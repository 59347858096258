@if (isShowHeader) {
  <div class="flex align-items-center justify-content-between">
    <div class="w-9rem h-1rem border-round-lg" [tuiSkeleton]="true"></div>
    <div class="w-13rem h-1rem border-round-lg" [tuiSkeleton]="true"></div>
  </div>
}
<div class="flex align-items-center justify-content-between mt-3 mb-4">
  <div class="flex align-items-center gap-2">
    @if (buttonsSize?.length) {
      @for (size of buttonsSize; track $index) {
        <div [class]="'h-2rem border-round-lg ' + size" [tuiSkeleton]="true"></div>
      }
    } @else {
      <div class="w-2rem h-2rem border-round-lg" [tuiSkeleton]="true"></div>
    }
  </div>
  @if (!isHideSearch) {
    <div class="w-12rem h-2rem border-round-lg" [tuiSkeleton]="true"></div>
  }
</div>

<div class="sk-table" [class.dark]="darkMode()">
  @for (r of rowNumber; track $index) {
    <div class="sk-table-row" [style]="rowStyle">
      @for (c of colNumber; track $index) {
        <div class="sk-table-col">
          <div class="sk-text" [tuiSkeleton]="true"></div>
        </div>
      }
    </div>
  }
</div>
