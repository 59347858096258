import {Component, Input} from '@angular/core';
import {TableAction, TableActionButton} from '@/app/entities/table/table-action.entity';
import {TuiButton, TuiDataList, TuiDropdown} from '@taiga-ui/core';

@Component({
  selector: 'app-table-action',
  standalone: true,
  imports: [
    TuiButton,
    TuiDataList,
    TuiDropdown
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableActionComponent {
  @Input() action: TableAction;
  @Input() id?: number;
  open = false;

  onClick(btn: TableActionButton) {
    this.open = false;

    if (btn.onClick) {
      btn.onClick(this.id);
    }
  }
}
