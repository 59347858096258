import { Routes } from '@angular/router';
import {UiKitComponent} from '@/app/pages/uikit/item.component';
import {BlankPageComponent} from '@/app/pages/blank/item.component';
import {RouteData} from '@/app/types/page/route-data.type';
import {AfterLoginPageComponent} from '@/app/pages/after-login/item.component';
import {TransactionPageComponent} from '@/app/pages/transaction/item.component';
import {DictionariesPageComponent} from '@/app/pages/dictionaries/item.component';
import {DictionaryTypePageComponent} from '@/app/pages/dictionaries/pages/dictionary-type/item.component';
import {allowedDictionaryTypesGuard} from '@/app/guards/allowed-dictionary-types.guard';
import {EmployeesPageComponent} from '@/app/pages/employees/item.component';
import {EmployeeWrapPageComponent} from '@/app/pages/employees/pages/employee-wrap/item.component';
import {
  EmployeeIssuesPageComponent
} from '@/app/pages/employees/pages/employee-wrap/pages/employee-issues/item.component';
import {
  EmployeeContractsPageComponent
} from '@/app/pages/employees/pages/employee-wrap/pages/emloyee-contracts/item.component';
import {ProjectsPageComponent} from '@/app/pages/projects/item.component';

export const routes: Routes = [
  {
    path: '',
    component: AfterLoginPageComponent,
    children: [
      {
        path: 'blank',
        component: BlankPageComponent,
        title: 'Blank Page',
        data: <RouteData>{
          icon: '@tui.panels-top-left',
          pageTitle: 'Page title - Blank',
          menuTitle: 'Blank',
          description: 'Blank description',
        }
      },
      {
        path: 'ui-kit',
        component: UiKitComponent,
        title: 'UiKit',
        data: <RouteData>{
          icon: '@tui.wallpaper',
          pageTitle: 'UiKit',
          menuTitle: 'UiKit',
          description: 'UiKit description',
        }
      },
      {
        path: 'transactions',
        component: TransactionPageComponent,
        title: 'Transactions',
        data: <RouteData>{
          icon: '@tui.arrow-right-left',
          pageTitle: 'Transactions',
          menuTitle: 'Transactions',
          description: 'Transactions description',
        }
      },
      {
        path: 'dictionaries',
        component: DictionariesPageComponent,
        title: 'Dictionaries',
        data: <RouteData>{
          icon: '@tui.list',
          pageTitle: 'Dictionaries',
          menuTitle: 'Dictionaries',
          description: 'Dictionaries description',
        },
      },
      {
        path: 'dictionaries/:type',
        component: DictionaryTypePageComponent,
        title: 'Dictionary',
        canActivate: [allowedDictionaryTypesGuard],
        data: <RouteData>{
         isHidden: true,
        },
      },
      {
        path: 'employees',
        component: EmployeesPageComponent,
        title: 'Employees',
        data: <RouteData>{
          icon: '@tui.users',
          pageTitle: 'Employees',
          menuTitle: 'Employees',
          description: 'Employees description',
        }
      },
      {
        path: 'employees/:employeeId',
        component: EmployeeWrapPageComponent,
        data: <RouteData>{
          isHidden: true,
        },
        children: [
          {
            path: 'issues',
            component: EmployeeIssuesPageComponent,
            data: <RouteData>{
              isHidden: true,
            },
          },
          {
            path: 'contracts',
            component: EmployeeContractsPageComponent,
            data: <RouteData>{
              isHidden: true,
            },
          }
        ]
      },
      {
        path: 'projects',
        component: ProjectsPageComponent,
        title: 'Projects',
        data: <RouteData>{
          icon: '@tui.monitor',
          pageTitle: 'Projects',
          menuTitle: 'Projects',
          description: 'Projects description',
        }
      },
      { path: '', redirectTo: 'ui-kit', pathMatch: 'full'}
    ]
  },
];
