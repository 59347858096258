<div class="select">
  <div class="select-origin"
       [ngClass]="{
          'multiple-selected': multiple && selected?.length,
          'readonly': readonly
       }"
       cdkOverlayOrigin
       #trigger="cdkOverlayOrigin"
       (click)="toggle()"
  >
    <label class="select-label" [class.select-label-active]="hasSelected">{{label}}</label>
    <ng-container *ngTemplateOutlet="headerContent"></ng-container>
    <button class="select-icon">
      <tui-icon
          icon="@tui.chevron-down"
      />
    </button>
  </div>
</div>
@if (isMobile) {
  <ng-template
    let-observer
    [tuiSheetDialogOptions]="{
        label: 'label'
    }"
    [(tuiSheetDialog)]="isOpen"
  >
    <div>
      <ng-container *ngTemplateOutlet="dropdownContent"></ng-container>
    </div>
  </ng-template>
} @else {
  <ng-template cdkConnectedOverlay
               [cdkConnectedOverlayOrigin]="trigger"
               [cdkConnectedOverlayOpen]="isOpen"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
               [cdkConnectedOverlayPositions]="positions"
               [cdkConnectedOverlayWidth]="originWidth"
               [cdkConnectedOverlayFlexibleDimensions]="true"
               cdkConnectedOverlayBackdropClass="select-backdrop"
               cdkConnectedOverlayPanelClass="select-overlay"
               (backdropClick)="overlayWrapper.close()"
               (detach)="overlayWrapper.close()"
               (positionChange)="onPositionChange($event)">

    <div (click)="overlayWrapper.close()" class="select-origin focus"
         [ngClass]="{
        'multiple-selected': multiple && selected?.length,
        'readonly': readonly
      }"
    >
      <label class="select-label" [class.select-label-active]="hasSelected">{{label}}</label>
      <ng-container *ngTemplateOutlet="headerContent"></ng-container>
      <button class="select-icon">
        <tui-icon
            icon="@tui.chevron-up"
        />
      </button>
    </div>
    <app-select-overlay-wrapper #overlayWrapper (onClose)="onClose()">
      <div class="select-dropdown">
        <ng-container *ngTemplateOutlet="dropdownContent"></ng-container>
      </div>
    </app-select-overlay-wrapper>
  </ng-template>
}

<ng-template #headerContent>
  <div class="select-origin-content">
    @if (!multiple) {
      <div class="select-origin-content-selected">
        @if (selected) {
          @if(selectHeader) {
            <ng-container *ngTemplateOutlet="selectHeader; context: {node: selected}"></ng-container>
          } @else {
            {{getLabel(selected)}}
          }
        }
      </div>

      @if (selected && !readonly && !loading) {
        <button class="select-origin-content-remove" (click)="removeSingle($event)">
          <tui-icon
              icon="@tui.x"
          />
        </button>
      }

    } @else {
      @for(item of selected; track item.key) {
        <p-chip [label]="getLabel(item)" [removable]="!readonly" (onRemove)="remove($event, item)">
          @if(selectHeader) {
            <ng-container *ngTemplateOutlet="selectHeader; context: {node: item}"></ng-container>
          }
        </p-chip>
      }
    }

    @if (loading) {
      <div class="select-loader flex justify-content-center">
        <p-progressSpinner ariaLabel="loading" styleClass="w-1rem h-1rem"></p-progressSpinner>
      </div>
    }
  </div>
</ng-template>

<ng-template #dropdownContent>

  <div class="select-dropdown-options selected-dropdown-option-filter"
       [ngClass]="{
        'not-full': totalItems <= 5,
        'has-filter': isSearch,
       }"
       [style]="'--select-elements:' + totalItems"
  >
    <!--    TODO: почему то виртаульный скролл скачет в дереве на хроме. В issue гитхаба висят запросы на фикс такого поведения, но пока без результата -->
    <p-tree
      #tree
      [metaKeySelection]="false"
      [value]="items"
      [selectionMode]="multiple ? 'multiple' : 'single'"
      [scrollHeight]="totalItems > 5 ? '240px' : undefined"
      [virtualScroll]="totalItems > 5"
      [virtualScrollItemSize]="46"
      [loading]="loading"
      [trackBy]="trackByIndex"
      [filter]="isSearch"
      [(selection)]="selected"
      (selectionChange)="select()"
      (onNodeExpand)="nodeExpand($event)"
    >
      @if (selectOption) {
        <ng-template let-node pTemplate="default">
          <ng-container *ngTemplateOutlet="selectOption; context: {node: node}"></ng-container>
        </ng-template>
      } @else {
        <ng-template let-node pTemplate="default">
          <div class="w-full text-overflow-ellipsis overflow-hidden white-space-nowrap min-w-0">
            {{node.label}}
          </div>
        </ng-template>
      }

      <ng-template let-node pTemplate="loadMore">
        <div class="load-more">
          <button tuiButton size="s" (click)="onLoadMore.emit()">{{node.label}}</button>
        </div>
      </ng-template>

      <ng-template pTemplate="empty">
        Ничего не найдено
      </ng-template>
    </p-tree>
  </div>
</ng-template>
