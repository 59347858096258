import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input, OnDestroy,
  OnInit
} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {delay} from 'rxjs/operators';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {CustomDate} from '@/app/core/classes/custom-date';
import {TuiInputDateModule, TuiInputDateRangeModule, TuiTextfieldControllerModule} from '@taiga-ui/legacy';
import {TuiDay, TuiDayRange} from '@taiga-ui/cdk';
import {dateToTuiDay} from '@/app/utils/input/dateToTuiDay';


/**
 * DatePicker component
 *
 * @example
 * Basic usage
 * <app-date-picker
 *  [formControl]="control"
 * ></app-date-picker>
 */
@Component({
  selector: 'app-date-picker',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TuiInputDateModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiInputDateRangeModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
  ]
})
export class DatePickerComponent extends AbstractNgModelComponent implements OnInit, OnDestroy {
  /**
   * Show clear button if value is selected
   */
  @Input() showClear: boolean = false;

  /**
   * Placeholder of date picker
   */
  @Input() placeholder: string = '';

  /**
   * Min date as Date or DateIso
   */
  @Input({transform: dateToTuiDay}) minDate: TuiDay;

  /**
   * Max date as Date or DateIso
   */
  @Input({transform: dateToTuiDay}) maxDate: TuiDay;

  /**
   * Date format
   */
  @Input() format: string;

  /**
   * Single or Range picker
   */
  @Input() isRange: boolean = false;

  control: FormControl;
  fb: FormBuilder;

  constructor(
    injector: Injector,
    fb: FormBuilder,
  ) {
    super(injector);
    this.fb = fb;
  }

  sub: Subscription;

  ngOnInit() {
    this.control = this.fb.control(null);

    this.sub = this.control.valueChanges
      .pipe(delay(100))
      .subscribe((v: null | TuiDay | TuiDayRange) => {
        if (v instanceof TuiDayRange) {
          this.changeData([
            (new CustomDate(v.from.year, v.from.month, v.from.day)).toIso(),
            (new CustomDate(v.to.year, v.to.month, v.to.day)).toIso(),
          ]);
          return;
        }

        if (v instanceof TuiDay) {
          this.changeData((new CustomDate(v.year, v.month, v.day)).toIso());
          return;
        }

        this.changeData(null);
      });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  changeData(data: any) {
    if (this.onChangeModel) {
      this.onChangeModel(data);
    }
  }

  override writeValue(value) {
    super.writeValue(value);
    if (Array.isArray(value) ) {
      if (value.length === 2) {
        this.control.setValue(
          new TuiDayRange(dateToTuiDay(value[0])!, dateToTuiDay(value[1])!
        ), {emitEvent: false});
      }
     } else {
      this.control.setValue(dateToTuiDay(value), {emitEvent: false});
    }
  }
}
