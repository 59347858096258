@if (pagination) {
  <div class="flex mt-4 align-items-center justify-content-between">
    <div class="flex align-items-center gap-2">
      @if ((pagination.perPage && pagination.perPage > 10) && treeTableRef) {
        <button
          tuiIconButton
          size="s"
          iconStart="@tui.arrow-up"
          appearance="flat"
          type="button"
          (click)="toTop()"
        ></button>
      }
      <p>Number of lines</p>
      <tui-input-number
          [formControl]="sizeControl"
          tuiTextfieldSize="s"
          class="size-input"
          [min]="8"
          [max]="99"
      >
        <input
            tuiTextfieldLegacy
        />
      </tui-input-number>
    </div>

    @if (pagination.totalPages && pagination.totalPages > 1) {
      <tui-pagination
          [index]="pagination.currentPage ? pagination.currentPage - 1 : 0"
          [length]="pagination?.totalPages ?? 1"
          (indexChange)="onChangePage($event)"
      />
    }
  </div>

}
