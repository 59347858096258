import {
  Component,
  forwardRef,
  inject,
  Input, OnDestroy,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {DataList} from '@/app/entities/common/data-list.entity';
import {TuiCheckbox} from '@taiga-ui/kit';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TuiCheckbox
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true
    }
  ]
})
export class CheckboxListComponent extends AbstractNgModelComponent implements OnInit, OnDestroy {

  @Input() items: DataList[] = [];
  @Input() minSelected?: number;
  @Input() maxSelected?: number;

  form = new FormGroup({})

  fb = inject(FormBuilder);
  objectKeys = Object.keys;
  sub: Subscription;

  ngOnInit() {
    this.items.forEach((item: DataList) => {
      this.form.addControl(item.id.toString(), this.fb.control(false))
    })

    this.sub = this.form.valueChanges.subscribe(v => {
      console.log({v})
      const keys = Object.keys(v);
      const selectedKeys = keys.filter(key => v[key]);
      const selectedItems = this.items.filter(item => selectedKeys.includes(item.id.toString()));
      if (selectedItems.length) {
        this.changeData(selectedItems.map(item => item.id));
        return;
      }

      this.changeData(null);
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  getLabel(slug: string): string {
    return this.items.find(i => i.id.toString() === slug)?.label ?? '';
  }

  getControl(slug: string): FormControl {
    return this.form.get(slug) as FormControl;
  }

  override writeValue(value) {
    super.writeValue(value);
    if (value && Array.isArray(value)) {
      value.forEach(v => {
        if (this.form.get(v.toString())) {
          this.form.get(v.toString())!.setValue(true, {emitEvent: false});
        }
      })
    }
  }

  changeData(data: any) {
    if (this.onChangeModel) {
      this.onChangeModel(data);
    }
  }
}
