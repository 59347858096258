<app-table-controls
    [buttons]="controlButton"
    [isHideSearch]="controlIsHideSearch"
    (onSearch)="onSearch.emit($event)"
/>

<div #tableMarker></div>
<p-treeTable
    #treeTable
    [columns]="tableColumns"
    [value]="tableRows"
    [scrollable]="true"
    [tableStyle]="{'min-width':'50rem'}"
    [scrollHeight]="tableHeight"
    [virtualScroll]="isVirtual"
    [virtualScrollItemSize]="60"
    [loading]="isBlur ? false : isLoading"
    class="table"
    [ngClass]="{
      'has-child': maxChildDeep > 1,
      'shadow-left': isShadow.left,
      'shadow-right': isShadow.right,
      'shadow-top': isShadow.top,
      'shadow-bottom': isShadow.bottom,
    }"
    (onNodeExpand)="recalcExpanded()"
    (onNodeCollapse)="recalcExpanded()"
>
  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      @if (maxChildDeep > 1) {
        <col [style.width.px]="30"/>
      }
      @for(col of columns; track col) {
        <col [style.width.px]="col.type === colTypes.action ? 48 : 250"/>
      }
    </colgroup>
  </ng-template>
  <ng-template pTemplate="header">
    @for (header of tableHeader; track header) {
      <tr>
        @if (maxChildDeep > 1) {
          <th></th>
        }
        @for (th of header; track th.slug) {
          <th [attr.colspan]="th.htmlElementAttributes.colspan"
              [attr.rowspan]="th.htmlElementAttributes.rowspan"
              [class]="th.htmlElementAttributes.class ? th.htmlElementAttributes.class.join(' ') : ''"
          >

<!--            [ttSortableColumn]="tableColumns.includes(th.slug) ? th.slug : undefined"-->
            <div class="table-title">
              {{th.title}}

              @if (th.description) {
                <tui-icon
                    icon="@tui.info"
                    [style.color]="'var(--text-color-secondary)'"
                    [style.height.px]="16"
                    [style.width.px]="16"
                    [tuiDropdown]="th.description"
                    tuiDropdownHover
                />
              }

              @if (th.filter) {
                <app-table-filter [filter]="th.filter"/>
              }
            </div>
<!--            @if (tableColumns.includes(th.slug)) {-->
<!--              <p-treeTableSortIcon [field]="th.slug" />-->
<!--            }-->
          </th>
        }
      </tr>
    }
  </ng-template>
  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr [ttRow]="rowNode" (click)="selectTr(rowNode)" [class.blured]="isBlur">
      @for (col of tableColumns; track col) {
        @if ($index === 0 && maxChildDeep > 1) {
          <td>
            <div
                class="table-toggler"
                [style.width.px]="30 - (rowNode.level * 4)"
            >
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            </div>
          </td>
        }
        <td ttEditableColumn>
          <div class="table-td"
              [ngClass]="{
                'td-action': col.type === colTypes.action,
                'td-progress': col.type === colTypes.progress,
              }"
          >
            @switch (col.type) {
              @case (colTypes.date) {
                {{ rowData[col.title]  | date: 'dd.MM.yyyy' }}
              }
              @case (colTypes.dateRange) {
                {{ rowData[col.title] }}
              }
              @case (colTypes.dateTime) {
                {{ rowData[col.title] }}
              }
              @case (colTypes.finances) {
                <app-currency [value]="rowData[col.title]" currency="USD"></app-currency>
              }
              @case (colTypes.financesWithColor) {
                {{ rowData[col.title] }}
              }
              @case (colTypes.number) {
                {{ rowData[col.title] }}
              }
              @case (colTypes.progress) {
                @if (rowData[col.title].calculation) {
                 <app-calculation-progress
                     class="w-full"
                     [calculation]="rowData[col.title].calculation"
                     [isSelf]="rowData[col.title].isSelf"
                     [isTime]="rowData[col.title].isTime"
                 />
                }
              }
              @case (colTypes.html) {
                {{ rowData[col.title] }}
              }
              @case (colTypes.callback) {
                <span class="table-callback" (click)="onClickCallback(rowData[col.title])">
                  {{ rowData[col.title]?.text }}
                </span>
              }
              @case (colTypes.price) {
                <div class="w-full">
                  <div class="table-price-label">{{rowData[col.title]?.billingModel === 'fix' ? 'Fix' : 'T&M'}}</div>
                  <p class="table-price-value">
                    @if (rowData[col.title]?.billingModel === 'fix') {
                      <app-currency [value]="rowData[col.title]?.price" currency="USD" />
                    } @else {
                      <app-currency [value]="rowData[col.title]?.hourRate" currency="USD" /> / h
                    }
                  </p>
                </div>
              }
              @case (colTypes.action) {
                <app-table-action [action]="rowData[col.title]" />
              }
              @case (colTypes.text) {
                {{ rowData[col.title] }}
              }
            }

            @if (col.action) {
              <app-table-action [action]="col.action" [id]="rowData['id']" class="ml-auto"/>
            }
          </div>
        </td>
      }
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr>
      @if (maxChildDeep > 1) {
        <td></td>
      }
      @for (col of tableConfig.footer; track col.title) {
        <td [attr.colspan]="col.htmlElementAttributes?.colspan ?? 1">
          <div class="table-td">
            {{col.title}}
          </div>
        </td>
      }
    </tr>
  </ng-template>

<!--  <ng-template pTemplate="summary">-->
<!--    <div class="h-3rem">-->
<!--      summary-->
<!--    </div>-->
<!--  </ng-template>-->

  <ng-template pTemplate="emptymessage" let-columns>
    <tr>
      <td [attr.colspan]="columns.length">
        <p class="text-center">No Data</p>
      </td>
    </tr>
  </ng-template>
</p-treeTable>

@if (pagination) {
  <app-table-pagination
      [pagination]="pagination"
      [isLoading]="isLoading"
      [treeTableRef]="treeTable"
      (changePage)="changePage.emit($event)"
      (changePageSize)="changePageSize.emit($event)"
  />
}



