import {Component, CUSTOM_ELEMENTS_SCHEMA, inject} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {TableData} from '@/app/entities/table/table-data.entity';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dictionaries-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    TableComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DictionariesPageComponent {
  router = inject(Router);

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Dictionary",
        "slug": "dictionary",
        "columnType": TableColumnType.callback,
      },
      {
        "title": "Items",
        "slug": "items",
      },
    ],
  });

  tableData: TableData[] = [
    {
      data: {
        dictionary: {
          text: 'Positions',
          onClick: () => {
            this.router.navigate(['/dictionaries/position']);
          }
        },
        items: 10
      }
    },
    {
      data: {
        dictionary: {
          text: 'Departments',
          onClick: () => {
            this.router.navigate(['/dictionaries/department']);
          }
        },
        items: 10
      }
    },
    {
      data: {
        dictionary: {
          text: 'Transaction categories',
          onClick: () => {
            this.router.navigate(['/dictionaries/transaction_category']);
          }
        },
        items: 10
      }
    }
  ];
}
