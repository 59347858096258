import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Injector, OnInit, OnDestroy
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {
  BaseFormControlComponent
} from '@/app/components/form-components/form-constructor/base/base-form-control.component';
import {FormElementServerError, FormElementValidation} from '@/app/entities/form/form-element.entity';
import {plainToInstance} from 'class-transformer';
import {Subscription} from 'rxjs';
import {CheckboxListComponent} from '@/app/components/form-components/checkbox-list/item';
import {FormSelectComponent} from '@/app/components/form-components/form-constructor/form-select/item';
import {DatePickerComponent} from '@/app/components/form-components/date-picker/item';
import {DateTimePickerComponent} from '@/app/components/form-components/datetime-picker/item';
import {SegmentComponent} from '@/app/components/form-components/segment/item';
import {InputComponent} from '@/app/components/form-components/input/item';
import {RadioComponent} from '@/app/components/form-components/radio/item.component';

/**
 * This component is only used in FormGroupComponent to render FormControls
 *
 * @example
 * <app-form-control
 *   [makeAllFormTouched]="false"
 *   [control]='control'
 * ></app-form-control>
 */
@Component({
  selector: 'app-form-control',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CheckboxListComponent,
    ReactiveFormsModule,
    FormSelectComponent,
    DatePickerComponent,
    DateTimePickerComponent,
    SegmentComponent,
    InputComponent,
    RadioComponent
  ]
})
export class FormControlComponent extends BaseFormControlComponent implements OnInit, OnDestroy {
  /**
   * FormControl
   */
  @Input() control: FormControl | any;

  /**
   * Slug of FormControl
   */
  @Input() controlSlug!: string;
  sub: Subscription;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.sub = this.control.valueChanges.subscribe(() => {
      this.setCaptions();
      this.checkServerErrors();
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  /**
   * Set captions based on errors and control validations.
   */
  setCaptions() {
    const errors = this.control.errors || {}
    const errorsKeys = Object.keys(errors);
    const controlValidations = this.control['field']().currentElementConfiguration.validation

    this.control.captions = [];
    
    errorsKeys.forEach(errorKey => {
      controlValidations.forEach(validation => {
        validation = plainToInstance(FormElementValidation, validation);
        if (!validation.affectValidation && validation.type === errorKey) {
          this.control.captions.push(validation)
          delete errors.errorKey
          this.control.setErrors(errors.length ? errors : null);
        }
      });
    })
  }

  /**
   * A function that checks and filters server errors.
   *
   */
  checkServerErrors() {
    this.control.serverErrors = (this.control.serverErrors || []).filter((item: FormElementServerError) => {
      if (!item.inited) {
        item.inited = true;
        return true;
      } else {
        return !item.hideOnChange;
      }
    })
  }

  /**
   * Retrieves the state of the control.
   *
   * @return {any} The state of the control, or null if it is undefined.
   */
  getState(): any {
    return this.control.touched ?? null;
  }

  /**
   * Retrieves the server errors from the control or an empty array if there are none.
   *
   * @return {Array} the server errors or an empty array
   */
  getServerErrors() {
    return this.control.serverErrors ?? []
  }

  /**
   * Retrieves the errors associated with the control.
   *
   * @return {Array} An array of errors associated with the control.
   */
  getErrors() {
    if (this.control.errors) {
      const controlValidations = this.control['field']().currentElementConfiguration.validation;
      const hasControlValidationError = controlValidations.filter(item => this.control.errors && this.control.errors[item.type]);
      if (!hasControlValidationError?.length && this.control.parent) {
        const parentValidation = this.control.parent['field']().currentElementConfiguration.validation;
        return parentValidation?.filter(item => {
          if (Object.keys(this.control.errors ?? {}).includes(item.type) && !item.affectValidation) {
            this.control.captions = this.control.captions?.filter(capItem => capItem.type !== item.type);
            this.control.captions.push(item);
            delete this.control.errors[item.type];
            this.control.setErrors(this.control.errors.length ? this.control.errors : null);
          }
          return this.control.errors && this.control.errors[item.type];
        });
      }
      return hasControlValidationError;
    }
    return [];
  }

  /**
   * Get captions from the control.
   *
   * @return {Array} Captions from the control, or an empty array if not available.
   */
  getCaptions() {
    return this.control.captions ?? []
  }

  /**
   * A function that computes messages based on server errors, errors, and captions.
   *
   * @return {Array} an array of computed messages
   */
  computedMessages() {
    return this.getState() ? [...this.getServerErrors(), ...this.getErrors(), ...this.getCaptions()] : null
  }

  /**
   * A function that returns a color based on the input color parameter.
   *
   * @param {string} color - The color to look up in the colors object.
   * @return {string} The corresponding color value from the colors object, or the original color if not found.
   */
  getMessageColor(color) {
    const colors = {
      red: `var(--tui-text-negative)`,
      yellow: `var(--passwordMediumBg)`,
      green: `var(--passwordStrongBg)`
    }

    return color ? colors[color] ?? color : 'var(--tui-text-negative)';
  }

  /**
   * A function that computes and returns the input style based on computed messages.
   *
   * @return {object} the style object for the input
   */
  computedInputStyle() {
    const style = {}

    if (this.computedMessages() && this.computedMessages()!.length) {
      style['border-color'] = this.getMessageColor(this.computedMessages()![0].color)
    }

    return style
  }
}
