import {TuiDay} from '@taiga-ui/cdk';

export function dateToTuiDay (date?: Date): TuiDay | null {
  if (!date) {
    return null;
  }

  const nDate = new Date(date);
  return new TuiDay(nDate.getFullYear(), nDate.getMonth(), nDate.getDate());
}
