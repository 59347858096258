<app-page-wrapper>
  <main-content>
    @if (firstLoading) {
      <app-table-skeleton [isHideSearch]="true" [buttonsSize]="['w-7rem', 'w-2rem']" />
    } @else {
      <app-table
          [controlButton]="tableButtons"
          [controlIsHideSearch]="true"
          [tableConfig]="tableCfg"
          [tableRows]="tableData"
          [isBlur]="isRefresh"
          [isLoading]="loading()"
      />
    }
  </main-content>
</app-page-wrapper>
