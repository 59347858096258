import { Injectable } from '@angular/core';
import {ApiCoreService} from '@/app/services/core/api-core.service';
import {HttpClient} from '@angular/common/http';
import {ApiPagination} from '@/app/entities/api/api-pagination.entity';
import {ApiDictionaryFilter, ApiDictionaryModifier} from '@/app/entities/api/types/api-dictionary.type';
import {DictionaryEntity} from '@/app/entities/dictionary/dictionary.entity';
import {DataList} from '@/app/entities/common/data-list.entity';

@Injectable({
  providedIn: 'root'
})
export class DictionaryService extends ApiCoreService<DictionaryEntity | DataList>{

  constructor(
    http: HttpClient,
  ) {
    super(http);
    this.url = 'dictionary';
  }

  override get(
    filter?: Partial<ApiDictionaryFilter>,
    modifier?: Partial<ApiDictionaryModifier>,
    pagination?: ApiPagination
  ) {
    return super.get(filter, modifier, pagination);
  }
}
