import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, HostBinding, Input,
  ViewChild,
} from '@angular/core';
import {TuiButton, TuiScrollbar} from '@taiga-ui/core';
import {IButtonModalData, IModalData, ModalType} from '@/app/modals/type/modal';
import {TuiPopover} from '@taiga-ui/cdk';
import {TuiButtonLoading} from '@taiga-ui/kit';
import {FormElementComponent} from '@/app/components/form-components/form-constructor/form-element/item';
import {FormControl, ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'app-common-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TuiButton,
    TuiScrollbar,
    TuiButtonLoading,
    FormElementComponent,
    ReactiveFormsModule
  ]
})
export class CommonModalComponent implements AfterViewInit {
  @Input() context!: TuiPopover<ModalType<IModalData<CommonModalComponent>>, string>;
  @HostBinding('class.modal-parent') setParent() {
    return true;
  }

  @ViewChild('formElement') formElement: FormElementComponent;
  form = new FormControl();

  makeAllFormTouched = false;

  ngAfterViewInit() {
    if (this.context.data.form) {
      this.formElement.build(this.context.data.form.fields, this.context.data.form?.value ?? null);
    }
  }

  close() {
    this.context.$implicit.complete();
    if (this.context.data.callbackAfterClose) {
      this.context.data.callbackAfterClose(this)
    }
  }

  onClick(btn: IButtonModalData<CommonModalComponent>) {
    if (btn.callback) {
      btn.callback(this);
    }
    if (btn.isClose) {
      if (this.context.data.callbackBeforeClose) {
        if (this.context.data.callbackBeforeClose(this, btn)) {
          this.close();
        }
      } else {
        this.close();
      }
    }
  }
}
