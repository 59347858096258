import 'reflect-metadata';
import {Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';

export class TableUserConfig extends Entity {
  @Type(() => TableUserConfigProfiles)
  profiles: TableUserConfigProfiles[];
}

export class TableUserConfigSorting extends Entity {
  columnSlug: string;
  direction: 'ASC' | 'DESC';
}

export class TableUserConfigOrder extends Entity {
  slug: string;
  isActive: boolean = true;
  order: number = 0;
  parentSlug: string;
  childSlugs: string[];

  @Type(() => TableUserConfigOrder)
  children: TableUserConfigOrder[];
}

export class TableUserConfigProfiles extends Entity {
  id: number;
  title: string;

  @Type(() => TableUserConfigSorting)
  sorting: TableUserConfigSorting;

  @Type(() => TableUserConfigOrder)
  headerColumns: TableUserConfigOrder[];

  @Type(() => TableUserConfigOrder)
  colsOrder: TableUserConfigOrder[];

  isActive: boolean = true;
}
