@for (subGroup of formArray.controls; track $index) {
  <div
      class="mb-2"
      [formGroup]="subGroup"
  >
    <app-form-group
        [makeAllFormTouched]="makeAllFormTouched"
        [formGroup]="subGroup"
    ></app-form-group>
    <div class="flex align-items-center mb-5">
      @if (formArray.controls.length === $index + 1) {
        <button
            tuiButton
            size="s"
            iconStart="@tui.plus"
            appearance="outline"
            (click)="addGroup(formArray)"
        >Add more</button>
      }
      <button tuiButton
              size="s"
              iconStart="@tui.trash"
              appearance="outline-destructive"
              class="ml-auto"
              (click)="removeGroup(formArray, $index)"
      >Delete</button>
    </div>
  </div>
} @empty {
  <button
      tuiButton
      size="s"
      iconStart="@tui.plus"
      appearance="outline"
      (click)="addGroup(formArray)"
  >Add more</button>
}
