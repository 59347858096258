import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input, OnDestroy,
  OnInit,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {delay} from 'rxjs/operators';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {CustomDate} from '@/app/core/classes/custom-date';
import {
  TuiInputDateTimeModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import {TuiDay, TuiTime} from '@taiga-ui/cdk';
import {dateToTuiDayTime} from '@/app/utils/input/dateToTuiDayTime';


/**
 * DatePicker component
 *
 * @example
 * Basic usage
 * <app-datetime-picker
 *  [formControl]="control"
 * ></app-date-picker>
 */
@Component({
  selector: 'app-datetime-picker',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiInputDateTimeModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true
    },
  ]
})
export class DateTimePickerComponent extends AbstractNgModelComponent implements OnInit, OnDestroy {
  /**
   * Show clear button if value is selected
   */
  @Input() showClear: boolean = false;

  /**
   * Placeholder of date picker
   */
  @Input() placeholder: string = '';

  /**
   * Min date as Date or DateIso
   */
  @Input({transform: dateToTuiDayTime}) minDate: [TuiDay, TuiTime] | null;

  /**
   * Max date as Date or DateIso
   */
  @Input({transform: dateToTuiDayTime}) maxDate: [TuiDay, TuiTime] | null;

  /**
   * Date format
   */
  @Input() format: string;

  /**
   * Single or Range picker
   */
  @Input() isRange: boolean = false;

  control = new FormControl<[TuiDay, TuiTime | null] | null>(null);
  fb: FormBuilder;
  form = new FormGroup({
    from: new FormControl<[TuiDay, TuiTime | null] | null>(null),
    to: new FormControl<[TuiDay, TuiTime | null] | null>(null),
  });

  constructor(
    injector: Injector,
    fb: FormBuilder,
  ) {
    super(injector);
    this.fb = fb;
  }

  subs: Subscription[] = [];

  ngOnInit() {
    this.subs.push(this.form.valueChanges
      .pipe(delay(100))
      .subscribe(v=> {
        if (!v) {
          this.changeData(null);
          return;
        }

        const from = this.hasDate(v.from!) ? v.from : null
        const timeFrom = from && from[1] ? from[1] : new TuiTime(0,0);

        const to = this.hasDate(v.to!) ? v.to : null
        const timeTo = to && to[1] ? to[1] : new TuiTime(0,0);

        if (from && to) {
          this.changeData([
            (new CustomDate(from[0].year, from[0].month, from[0].day, timeFrom!.hours, timeFrom!.minutes)).toIso(),
            (new CustomDate(to[0].year, to[0].month, to[0].day, timeTo!.hours, timeTo!.minutes)).toIso(),
          ]);
        } else {
          this.changeData(null);
        }
      }));
    this.subs.push(this.control.valueChanges
      .pipe(delay(100))
      .subscribe((v: null | [TuiDay, TuiTime | null]) => {
        if (!v) {
          this.changeData(null);
          return;
        }

        const time = [v[1]?.hours ?? 0, v[1]?.minutes ?? 0]

        this.changeData((new CustomDate(v[0].year, v[0].month, v[0].day, ...time)).toIso());
      }));
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  changeData(data: any) {
    if (this.onChangeModel) {
      this.onChangeModel(data);
    }
  }

  override writeValue(value) {
    super.writeValue(value);
    if (Array.isArray(value) ) {
      if (value.length === 2) {
        this.form.setValue({
          from: dateToTuiDayTime(value[0]),
          to: dateToTuiDayTime(value[1])
        }, {emitEvent: false})
      }
     } else {
      this.control.setValue(dateToTuiDayTime(value), {emitEvent: false});
    }
  }

  hasDate(value: TuiDay | [TuiDay | null, TuiTime | null] | null): boolean {
    return value && value[0] ? true: false
  }
}
