<div class="flex mb-4 align-items-center justify-content-between">
  <div class="flex gap-2 align-items-center">
    @for (btn of buttons; track $index) {
      @if (btn.button) {
        <button
            tuiButton
            [iconStart]="btn.button?.icon ?? ''"
            size="s"
            [appearance]="btn.button.appearance ?? 'outline'"
            (click)="onClick(btn.button)"
            [disabled]="btn.button.disabled ? btn.button.disabled() : false"
        >{{btn.button?.label}}</button>
      } @else if (btn.template) {
        <ng-container [ngTemplateOutlet]="btn.template"></ng-container>
      }
    }
  </div>


  @if (!isHideSearch) {
    <tui-input
        tuiTextfieldIconLeft="@tui.search"
        [tuiTextfieldSize]="'s'"
        [tuiTextfieldCleaner]="true"
        [formControl]="search"
    >
      Search
      <input tuiTextfieldLegacy />
    </tui-input>
  }
</div>
