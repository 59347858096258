import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {MenuComponent} from '@/app/components/page/menu/item.component';

@Component({
  selector: 'app-after-login',
  standalone: true,
  imports: [
    RouterOutlet,
    MenuComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class AfterLoginPageComponent {

}
