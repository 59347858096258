import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {TuiPagination} from '@taiga-ui/kit';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {TuiInputNumberModule, TuiTextfieldControllerModule} from '@taiga-ui/legacy';
import {TreeTable} from 'primeng/treetable';
import {TuiButton} from '@taiga-ui/core';

@Component({
  selector: 'app-table-pagination',
  standalone: true,
  imports: [
    TuiPagination,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiButton
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TablePaginationComponent implements OnInit,  OnChanges, OnDestroy{
  @Input() pagination: Partial<ApiResultPagination>;
  @Input() isLoading: boolean;
  @Input() treeTableRef: TreeTable;

  @Output() changePage = new EventEmitter<ApiPagination>();
  @Output() changePageSize = new EventEmitter<number>();

  sizeControl = new FormControl();
  sub: Subscription;

  ngOnInit() {
    if (this.pagination?.perPage) {
      this.sizeControl.setValue(this.pagination.perPage < 8 ? 8 : this.pagination.perPage , {emitEvent: false});
    } else {
      this.sizeControl.setValue(40, {emitEvent: false});
    }

    this.sub = this.sizeControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      if (value < 8) {
        value = 8;
        this.sizeControl.setValue(value, {emitEvent: false});
      }

      this.changePageSize.emit(value);
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const {isLoading} = changes;

    if (isLoading?.currentValue) {
      this.sizeControl.disable({emitEvent: false});
    } else {
      this.sizeControl.enable({emitEvent: false});
    }
  }

  onChangePage(page: number) {
    this.changePage.emit({
      perPage: this.sizeControl.value,
      currentPage: page
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  toTop() {
    if (this.treeTableRef) {
      this.treeTableRef.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
