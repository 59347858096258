import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {DataList} from '@/app/entities/common/data-list.entity';
import {Subscription} from 'rxjs';
import {CommonModule} from '@angular/common';
import {TuiRadioList} from '@taiga-ui/kit';
import {TuiTitle} from '@taiga-ui/core';

@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TuiRadioList,
    TuiTitle
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true
    }
  ]
})
export class RadioComponent extends AbstractNgModelComponent  implements OnInit, OnDestroy {
  @Input() items: DataList[] = [];
  @Input() direction: 'column' | 'row' = 'row';
  control = new FormControl();
  sub: Subscription;

  ngOnInit() {
    this.sub = this.control.valueChanges.subscribe(v => {
      if (this.onChangeModel) {
        this.onChangeModel(v.id);
        this.onTouchModel();
      }
    })
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  override writeValue(value) {
    super.writeValue(value);
    if (value) {
      this.control.setValue(this.items.find(i => i.id === value));
    }
  }
}
