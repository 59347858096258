<button
    tuiButton
    iconStart="@tui.calendar"
    size="s"
    [appearance]="!value ? 'outline-grayscale' : 'primary'"
    [disabled]="disabled"
    type="button"
    [tuiAppearanceState]="open ? 'active' : null"
    [tuiDropdown]="dropdown"
    [(tuiDropdownOpen)]="open"
></button>

<ng-template
  #dropdown
  let-close
>
  <tui-calendar
      [value]="value"
      (dayClick)="onDayClick($event)"
  />
  <div class="flex justify-content-center">
    <button
        tuiButton
        size="s"
        type="button"
        appearance="flat"
        class="clear-button"
        (click)="onClear()"
    >
      Clear
    </button>
  </div>
</ng-template>
