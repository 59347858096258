import { Injectable } from '@angular/core';
import {ApiCoreService} from '@/app/services/core/api-core.service';
import {EmployeeContract} from '@/app/entities/employee/employee-contract.entity';
import {HttpClient} from '@angular/common/http';
import {ApiPagination} from '@/app/entities/api/api-pagination.entity';
import {ApiEmployeeContractFilter} from '@/app/entities/api/types/api-employee-contract.type';

@Injectable({
  providedIn: 'root'
})
export class EmployeeContractService extends ApiCoreService<EmployeeContract>{

  constructor(
    http: HttpClient,
  ) {
    super(http);
    this.url = 'employee/contract';
  }

  override get(
    filter?: Partial<ApiEmployeeContractFilter>,
    pagination?: ApiPagination
  ) {
    return super.get(filter, undefined, pagination);
  }
}
