import { Injectable } from '@angular/core';
import {ApiCoreService} from '@/app/services/core/api-core.service';
import {Node} from '@/app/entities/nodes/node.entity';
import {HttpClient} from '@angular/common/http';
import {ApiNodeFilter, ApiNodeModifier} from '@/app/entities/api/types/api-node.type';
import {DataList} from '@/app/entities/common/data-list.entity';
import {TreeNode} from '@/app/entities/common/tree-node.entity';

@Injectable({
  providedIn: 'root'
})
export class NodeService extends ApiCoreService<Node | TreeNode<Node>>{

  constructor(
    http: HttpClient,
  ) {
    super(http);
    this.url = 'node';
  }

  override get(
    filter?: Partial<ApiNodeFilter>,
    modifier?: Partial<ApiNodeModifier>
  ) {
    return super.get(filter, modifier, undefined);
  }
}
