import {HttpErrorResponse, HttpEventType, HttpInterceptorFn,} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {inject} from '@angular/core';
import {TuiAlertService} from '@taiga-ui/core';
import {ApiResponse} from '@/app/entities/api/api-response.entity';
import {throwError} from 'rxjs';

export const messageInterceptor: HttpInterceptorFn = (req, next) => {
  const alerts = inject(TuiAlertService);

  return next(req).pipe(
    tap(event => {
      if (event.type === HttpEventType.Response) {
        const body = event.body as ApiResponse<any>;
        if (body.message) {
          alerts
            .open<boolean>(null, {
              label: body.message,
              appearance: 'warning',
            }).subscribe();
        }
      }
    }),
    catchError((err: HttpErrorResponse) => {
      if (err.error) {
        const body = err.error as ApiResponse<any>;
        if (body.message) {
          alerts
            .open<boolean>(body.message, {
              label: 'Error',
              appearance: 'negative',
            }).subscribe();
        }
      }

      return throwError(() => err);
    })
  );
};
