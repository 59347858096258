import {Component, Input} from '@angular/core';
import {HourDurationPipe} from '@/app/pipes/hour-duration.pipe';

@Component({
  selector: 'app-hour-duration',
  standalone: true,
  imports: [
    HourDurationPipe
  ],
  templateUrl: './hour-duration.component.html',
  styleUrl: './hour-duration.component.scss'
})
export class HourDurationComponent {
  @Input() hour: number;
  @Input() format: ('h' | 'm')[] = ['h', 'm'];
}
