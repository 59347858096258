import {Component, inject, Input} from '@angular/core';
import {TuiSkeleton} from '@taiga-ui/kit';
import {numberToArray} from '@/app/utils/input/numberToArray';
import {TUI_DARK_MODE} from '@taiga-ui/core';

@Component({
  selector: 'app-table-skeleton',
  standalone: true,
  imports: [
    TuiSkeleton
  ],
  templateUrl: './table-skeleton.component.html',
  styleUrl: './table-skeleton.component.scss'
})
export class TableSkeletonComponent {
  protected readonly darkMode = inject(TUI_DARK_MODE);

  @Input() isShowHeader: boolean;
  @Input() isHideSearch: boolean;

  // Need use only width class from prieflex
  // Example: ['w-2rem', 'w-4rem']
  @Input() buttonsSize: string[];

  @Input({transform: numberToArray}) rowNumber: any[] = numberToArray(5);
  @Input({transform: numberToArray}) colNumber: any[] = numberToArray(5);

  get rowStyle(): string {
    return `grid-template-columns: repeat(${this.colNumber.length}, minmax(0, 1fr));`
  }
}
