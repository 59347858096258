<app-page-wrapper>
  <main-content>
    @if (firstLoading) {
      <app-table-skeleton [buttonsSize]="['w-8rem', 'w-2rem', 'w-2rem']"/>
    } @else {
      <app-table
          [controlButton]="tableButtons"
          [tableConfig]="tableCfg"
          [tableRows]="tableData"
          [isLoading]="tableLoading"
          [isBlur]="isRefreshing"
          [pagination]="tablePagination"
          (onSearch)="onSearch($event)"
          (changePage)="onChangePagination($event)"
          (changePageSize)="onChangePageSize($event)"
      />
    }
  </main-content>
</app-page-wrapper>

<ng-template #dateFilter>
  <app-table-date-filter (onDateChange)="onDateChange($event)"/>
</ng-template>
