import {
  Component,
  EventEmitter, Input,
  Output,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
// import {TriggerQueryAction, TriggerQueryEntity } from 'lookin-sdk';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';

@Component({
 template: '',
})
export abstract class BaseFormControlComponent extends AbstractNgModelComponent {
  /**
   * Parent loader state
   */
  @Input() loader: boolean = false;
  /**
   * Indicates whether all forms have been set as touched.
   */
  @Input() makeAllFormTouched: boolean = false;

  @Output() additionalFormIsInvalid = new EventEmitter<boolean>();


  objectKeys = Object.keys;

  isFormControl(control: FormControl) {
    return control instanceof FormControl;
  }

  isFormGroup(group: FormGroup) {
    return group instanceof FormGroup;
  }

  isFormArray(array: FormArray) {
    return array instanceof FormArray;
  }
}
