import {
  ChangeDetectionStrategy,
  Component, DoCheck,
  EventEmitter, forwardRef,
  Injector,
  Input, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {InputFocusedDirective} from '@/app/core/directives/input-focused.directive';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {
  TuiInputModule,
  TuiInputNumberModule, TuiInputPhoneModule,
  TuiInputRangeModule, TuiTextareaModule,
  TuiTextfieldControllerModule
} from '@taiga-ui/legacy';
import {InputPatternDirective} from '@/app/core/directives/input-pattern.directive';
import {PasswordComponent} from '@/app/components/form-components/password/item';
import {TuiSwitch} from '@taiga-ui/kit';


/**
 * It is responsible for rendering input elements and managing their values.
 *
 * @example
 * For input text
 *
 * <app-input
 *   [FormControl]="myFormControl"
 *   [type]="'text'"
 *   [placeholder]="'Enter your name'"
 *   [minLength]="3"
 *   [maxLength]="10">
 * </app-input>
 *
 */
@Component({
  selector: 'app-input',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ],
  imports: [
    TuiInputModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    InputPatternDirective,
    TuiInputNumberModule,
    TuiInputRangeModule,
    TuiInputPhoneModule,
    TuiTextareaModule,
    PasswordComponent,
    TuiSwitch
  ]
})
export class InputComponent extends AbstractNgModelComponent implements OnInit, DoCheck, OnDestroy {
  @ViewChild(InputFocusedDirective) inputFocused: InputFocusedDirective;
  @Input() override readonly: boolean = false;
  @Input() parentSlug: string = '';

  /**
   * Type of input
   */
  @Input() type: 'text' | 'number' | 'number-range' | 'phone' | 'textarea' | 'toggle' | 'password' | 'pattern' = 'text';

  /**
   * HTML type of input (e.g. 'email', 'tel', 'password', etc.)
   *
   * This attribute specifies the type of input to display
   * (text, password, email, date, etc.).
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-type}
   */
  @Input() htmlType: string = 'text';

  /**
   * Show clear icon
   */
  @Input() clearable: boolean = false;

  /**
   * Input placeholder
   */
  @Input() placeholder: string = '';

  /**
   * Min length of input
   */
  @Input() minLength: number = 0;

  /**
   * Max length of input
   */
  @Input() maxLength: number = 1000;

  /**
   * Max value only for type number or number range
   */
  @Input() maxValue: number = 1000;

  /**
   * Min value only for type number or number range
   */
  @Input() minValue: number = 0;

  /**
   * CountryCode only for type phone
   */
  @Input() countryCode: string;

  /**
   * Mask only for type phone
   */
  @Input() mask: string;

  /**
   * characterPattern only for type pattern
   */
  @Input() characterPattern: string;

  /**
   * Rows only for type textarea
   */
  @Input() rows: number = 5;

  /**
   * Cols only for type textarea
   */
  @Input() cols: number = 30;

  /**
   * Enable or disable the feedback mode for password
   * @default false
   */
  @Input() feedback: boolean = false;

  /**
   * Custom style for input element
   *
   * @example
   * {
   *   'border-color': '#f00',
   *   'border-width': '2px'
   * }
   */
  @Input() inputStyle: object;

  /**
   * Placeholder character in mask, default is underscore.
  */
  @Input() slotChar: string = '_';

  @Input() prefix: string = '';

  /**
   * onInput Output event
   */
  @Output() onInput = new EventEmitter<any>();

  /**
   * onFocus Output event
   */
  @Output() onFocus = new EventEmitter<Event>();

  /**
   * onBlur Output event
   */
  @Output() onBlur = new EventEmitter<Event>();

  /**
   * onKeyDown Output event
   */
  @Output() onKeyDown = new EventEmitter<KeyboardEvent | Event>();

  control = new FormControl;
  @ViewChild('passwordEl') passwordEl;
  @ViewChild('maskEl') maskEl;

  sub: Subscription;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.sub = this.control.valueChanges
      .pipe(
        debounceTime(100)
      ).subscribe(v => {
        if (this.onChangeModel) {
          this.onChangeModel(v);
          this.onTouchModel();
        }
    })
  }

  ngDoCheck() {
    this.cdRef.markForCheck();
    this.setInputStyleForInputTypeMask();
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  setInputStyleForInputTypeMask() {
    if (this.type === 'phone' && this.maskEl) {
      this.maskEl.inputViewChild.nativeElement.style = JSON.stringify(this.inputStyle).slice(1, -1).replace(/"/g, '')
    }
  }

  override writeValue(value: any) {
    this.control.setValue(value, {emitEvent: false});
  }

  passwordMeter() {
    if (this.passwordEl && this.passwordEl.meter) {
      const text = this.passwordEl.infoText + ' пароль';
      const passwordStrength = this.passwordEl.meter.strength;
      const passwordStrengthWithFirstUpperLeter = passwordStrength.replace(passwordStrength[0], passwordStrength[0].toUpperCase());
      const color = `var(--password${passwordStrengthWithFirstUpperLeter}Bg)`

      return { text, color }
    }
    return null
  }

  passwordElComputedInputStyle() {
    const style = {...this.inputStyle};

    if (this.passwordMeter()) {
      style['border-color'] = this.passwordMeter()?.color;
    }

    return style;
  }

  onPaste(event: ClipboardEvent) {
    // @ts-ignore
    const pastedText = event.clipboardData.getData('text/plain');

    if (this.htmlType === 'tel') {
      const formattedPhone = this.handlePhoneNumber(pastedText);
      this.control.setValue(formattedPhone);
    }
  }

  handlePhoneNumber(phone: string): string {
    if (phone.length > 10) {
      return phone.replace(/^(\8)/, '+7');
    } else if (phone.length === 10) {
      return '+7' + phone
    } else {
      return phone
    }
  }

  clearControl() {
    this.control.setValue('');
  }
}
