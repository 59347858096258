<div class="menu" [class.collapse]="!isOpen()">
  <div>
    <div class="menu-logo menu-content">
      <div class="menu-logo-img menu-content-img">
        <img src="/assets/image/bb-logo.svg" alt="BB">
      </div>
      <div class="menu-logo-text menu-content-text">Budget Badger</div>
    </div>

    <div class="menu-list">
      @for (item of menuList; track item.fullPath) {
        <a
            [routerLink]="item.fullPath"
            routerLinkActive="selected"
            class="menu-link menu-content"
        >
          <span class="menu-link-img menu-content-img">
            <tui-icon [icon]="item.icon" />
          </span>
          <span class="menu-link-text menu-content-text">{{item.menuTitle}}</span>
        </a>
      }
    </div>
  </div>

  <div>
    <div class="menu-sync menu-content">
      <div class="menu-sync-img menu-content-img">
        <tui-icon icon="@tui.clock" />
      </div>
      <div class="menu-sync-text menu-content-text">
        <span>last sync:</span>
        12.06.2024 12:15
      </div>
    </div>
    <button
        class="menu-close"
        tuiIconButton
        size="xs"
        [iconStart]="isOpen() ? '@tui.arrow-left' : '@tui.arrow-right'"
        appearance="flat"
        (click)="isOpen.set(!isOpen())"
    ></button>
  </div>
</div>
