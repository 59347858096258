<button
    tuiIconButton
    [appearance]="hasData ? 'primary' : 'flat'"
    iconStart="@tui.filter"
    size="xs"
    [tuiDropdownSided]="true"
    [tuiDropdown]="dropdown"
    [tuiAppearanceState]="open ? 'active' : null"
    [tuiDropdownOpen]="open"
    (tuiDropdownOpenChange)="toggleOpen($event)"
></button>

<ng-template #dropdown let-close>
  <div class="p-2 table-filter">
    <p-tree
        [value]="filter.options"
        [selectionMode]="filter.isSingle ? 'single' : 'checkbox'"
        scrollHeight="300px"
        [filter]="false"
        [(selection)]="selection"
    ></p-tree>

    <div class="flex gap-2 mt-2">
      <button
          tuiButton
          appearance="flat"
          size="s"
          class="font-medium"
          (click)="onReset()"
      >Clear</button>

      <button
          tuiButton
          appearance="flat"
          size="s"
          class="font-medium ml-auto"
          (click)="onCancel()"
      >Cancel</button>

      <button
          tuiButton
          size="s"
          class="font-medium"
          (click)="onApprove()"
      >Apply</button>
    </div>
  </div>
</ng-template>
