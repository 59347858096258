<div class="modal">
  @if (context.data.title) {
    <div class="modal-title">{{context.data.title}}</div>
  }
  <tui-scrollbar class="modal-content">
    <div class="modal-content_box">
      @if (context.data.form) {
        <app-form-element
          #formElement
          [formControl]="form"
          [makeAllFormTouched]="makeAllFormTouched"
        ></app-form-element>
      }

      @if (context.data.text) {
        <p>{{context.data.text}}</p>
      }
    </div>
  </tui-scrollbar>
  <div class="modal-footer flex justify-content-end align-items-center gap-2">
    @for (btn of context.data.buttons; track $index) {
      <button
          tuiButton
          [loading]="!!context.data.isLoading ? context.data.isLoading() : false"
          [appearance]="btn.settings.appearance"
          size="s"
          (click)="onClick(btn)"
      >
        {{btn.settings.title}}
      </button>
    }
  </div>
</div>
