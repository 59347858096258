import {
  ChangeDetectionStrategy,
  Component, HostBinding,
  inject,
} from '@angular/core';
import {TuiDialogCloseService, TuiIcon} from '@taiga-ui/core';
import {NgComponentOutlet} from '@angular/common';
import {injectContext} from '@taiga-ui/polymorpheus';
import {TuiPopover, tuiTakeUntilDestroyed} from '@taiga-ui/cdk';
import {ModalType} from '@/app/modals/type/modal';

@Component({
  selector: 'app-parent-modal',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TuiDialogCloseService],
  imports: [
    NgComponentOutlet,
    TuiIcon
  ]
})
export class ParentModalComponent {
  protected readonly context = injectContext<TuiPopover<ModalType<any>, boolean>>();
  constructor() {
    inject(TuiDialogCloseService)
      .pipe(tuiTakeUntilDestroyed())
      .subscribe(() => this.context.$implicit.complete());
  }

  @HostBinding('style.max-width.px') get maxWidth() {
    return this.context.data?.maxWidth ?? 450;
  }

  @HostBinding('class.modal-large') get isLarge() {
    return this.context.size === 'large';
  }

  get inputContext(): {inputs: Record<string, unknown>} {
    return {inputs: {context: this.context}}
  }

  close() {
    this.context.$implicit.complete();
  }
 }
