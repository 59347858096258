import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RouteData} from '@/app/types/page/route-data.type';
import {Subscription} from 'rxjs';
import {PageWrapperButton} from '@/app/types/page/page-wrapper-button.type';
import {TuiButton, TuiDropdown, TuiIcon} from '@taiga-ui/core';
import {TuiSkeleton} from '@taiga-ui/kit';

@Component({
  selector: 'app-page-wrapper',
  standalone: true,
  imports: [
    TuiIcon,
    TuiDropdown,
    TuiButton,
    TuiSkeleton
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class PageWrapperComponent implements OnInit, OnDestroy {
  @Input() buttons: PageWrapperButton[] = [];
  @Input() customTitle: string;
  @Input() customDescription: string;
  @Input() isLoading: boolean;

  route = inject(ActivatedRoute);


  routeData = <RouteData>{};
  sub: Subscription;

  ngOnInit() {
    this.sub = this.route.data
      .subscribe(routeData => {
        if (routeData) {
          this.routeData = routeData as RouteData;
        }
      })
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onClick(btn: PageWrapperButton) {
    if (btn.callback) {
      btn.callback();
    }
  }
}
