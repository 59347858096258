import {Component, Input, OnInit} from '@angular/core';
import {NodeCalculation} from '@/app/entities/nodes/node-calculation.entity';
import {NgClass} from '@angular/common';
import {TuiDropdown} from '@taiga-ui/core';
import {CurrencyComponent} from '@/app/components/utils/currency/currency.component';

interface ProgressValue {
  value: number;
  size: number;
}

interface ProgressOverValue extends ProgressValue {
  currentValue: number;
  currentSize: number;
}

interface ProgressSize {
  expenses: ProgressValue;
  cost: ProgressValue;
  overRevenue: ProgressOverValue;
}

@Component({
  selector: 'app-calculation-progress',
  standalone: true,
  imports: [
    NgClass,
    TuiDropdown,
    CurrencyComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class CalculationProgressComponent implements OnInit {
  @Input() calculation: NodeCalculation;
  @Input() isSelf: boolean;
  @Input() isTime: boolean;
  @Input() billingModel?: 'fix' | 'time_and_material';

  sizeData: ProgressSize;

  ngOnInit() {
    this.sizeData = this.progressCalc();
  }

  progressCalc() {
    const size: ProgressSize = {
      expenses: {
        value: 0,
        size: 0
      },
      cost: {
        value: 0,
        size: 0
      },
      overRevenue: {
        value: 25,
        size: 0,
        currentValue: 0,
        currentSize: 0,
      }
    }

    const revenue = this.calculation.revenue.value;
    const expenses = this.calculation.expenses.value + this.calculation.organizationExpenses.value;

    size.expenses.value = expenses > 0 ? Math.round((expenses / revenue) * 100) : 0;
    size.cost.value = this.calculation.cost.value > 0 ? Math.round((this.calculation.cost.value / revenue) * 100) : 0;
    let total = size.expenses.value + size.cost.value;

    const calcSize = (p: number) => {
      size.expenses.size = size.expenses.value > 0 ? Math.round(size.expenses.value / p) : 0;
      size.cost.size = size.cost.value > 0 ? Math.round(size.cost.value / p) : 0;
      size.overRevenue.size = size.overRevenue.value > 0 ? Math.round(size.overRevenue.value / p) : 0;
    }

    if (total <= 100) {
      let p = 125 * 0.01;

      calcSize(p);
    } else {
      size.overRevenue.currentValue = total - 100;
      if (total < 125) {
        total = 125;
      }
      let p = total * 0.01;
      const totalRevenue = expenses + this.calculation.cost.value;
      size.cost.value = this.calculation.cost.value > 0 ? Math.round((this.calculation.cost.value / totalRevenue) * 100) : 0;
      size.expenses.value =  expenses > 0 ? Math.round((expenses / totalRevenue) * 100) : 0;
      size.overRevenue.value = totalRevenue - revenue > 0 ? Math.round(((totalRevenue - revenue) / revenue) * 100) : 0;
      size.overRevenue.value = size.overRevenue.value < 25 ? 25 : size.overRevenue.value;

      size.overRevenue.currentSize = size.overRevenue.currentValue > 0 ? (size.overRevenue.currentValue / size.overRevenue.value) * 100 : 0;
      calcSize(p);
    }

    return size;
  }

  get totalValue(): number {
    if (!this.sizeData) {
      return 0;
    }

    return this.sizeData.cost.value + this.sizeData.expenses.value;
  }
}
