import {AfterViewInit, Directive, ElementRef, OnDestroy, signal, WritableSignal} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
  selector: '[appInputFocused]',
  standalone: true
})
export class InputFocusedDirective implements AfterViewInit, OnDestroy {
  constructor(
    private el: ElementRef,
  ) { }

  inputElement: HTMLInputElement;
  isFocus: WritableSignal<boolean> = signal(false);

  subs: Subscription[] = [];

  ngAfterViewInit() {
    this.inputElement = this.el.nativeElement.querySelector('input, textarea');
    if (this.inputElement) {
      this.subs.push(fromEvent(this.inputElement, 'focus').subscribe(_ => {
        this.isFocus.set(true);
      }));

      this.subs.push(fromEvent(this.inputElement, 'blur').subscribe(_ => {
        this.isFocus.set(false);
      }));
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
