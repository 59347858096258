@switch (type) {
  @case ('text') {
    <tui-input
        [formControl]='control'
        [maxlength]="maxLength"
        [minlength]="minLength"
        [readOnly]="readonly"
        [tuiTextfieldCleaner]="clearable"
    >
      {{label}}
      <input
          tuiTextfieldLegacy
          [type]="htmlType"
          (input)="onInput.emit($event)"
          (focus)="onFocus.emit($event)"
          (blur)="onBlur.emit($event)"
          (keydown)="onKeyDown.emit($event)"
      />
    </tui-input>
  }
  @case ('pattern') {
    <tui-input
        [formControl]='control'
        [maxlength]="maxLength"
        [minlength]="minLength"
        [readOnly]="readonly"
        [tuiTextfieldCleaner]="clearable && !readonly"
    >
      {{label}}
      <input
          tuiTextfieldLegacy
          [type]="htmlType"
          regexInput
          [regexInput]="characterPattern"
          (input)="onInput.emit($event)"
          (focus)="onFocus.emit($event)"
          (blur)="onBlur.emit($event)"
          (keydown)="onKeyDown.emit($event)"
      />
    </tui-input>
  }
  @case ('number') {
    <tui-input-number
        [formControl]='control'
        [min]="minValue"
        [max]="maxValue"
        [readOnly]="readonly"
        [tuiTextfieldCleaner]="clearable && !readonly"
        [tuiTextfieldPrefix]="prefix"
        (input)="onInput.emit($event)"
        (focus)="onFocus.emit($event)"
        (blur)="onBlur.emit($event)"
        (keydown)="onKeyDown.emit($event)"
    >
      {{label}}
    </tui-input-number>
  }
  @case ('number-range') {
    <tui-input-range
        [formControl]='control'
        [min]="minValue"
        [max]="maxValue"

    >
      {{label}}
    </tui-input-range>
  }
  @case ('phone') {
    <tui-input-phone
        [formControl]='control'
        [countryCode]="countryCode"
        [phoneMaskAfterCountryCode]="mask"
        [tuiTextfieldCleaner]="clearable && !readonly"
        [readOnly]="readonly"
        (input)="onInput.emit($event)"
        (focus)="onFocus.emit($event)"
        (blur)="onBlur.emit($event)"
        (keydown)="onKeyDown.emit($event)"
        (paste)="onPaste($event)"
    >
      {{label}}
    </tui-input-phone>
  }
  @case ('textarea') {
    <tui-textarea
        [formControl]='control'
        [readOnly]="readonly"
        (input)="onInput.emit($event)"
        (focus)="onFocus.emit($event)"
        (blur)="onBlur.emit($event)"
        (keydown)="onKeyDown.emit($event)"
    >
      {{label}}
    </tui-textarea>
  }
  @case ('toggle') {
    <input
        tuiSwitch
        type="checkbox"
        [formControl]='control'
        [readOnly]="readonly"
    />
  }
  @case ('password') {
    <app-password
        [formControl]="control"
        [label]="label"
        [feedback]="feedback"
        [inputStyle]="inputStyle"
      ></app-password>
  }
}
