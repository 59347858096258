import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourDuration',
  standalone: true
})
export class HourDurationPipe implements PipeTransform {
  transform(hours: number, format: ('h' | 'm')[] = ['h', 'm']): string {
    if (isNaN(hours)) {
      return '';
    }

    const fullHours = Math.floor(hours);
    const remainingMinutes = Math.round((hours - fullHours) * 60);

    if (format.includes('h') && format.includes('m')) {
      return `${fullHours} h ${remainingMinutes} m`;
    } else if (format.includes('h')) {
      return `${fullHours} h`;
    } else if (format.includes('m')) {
      const totalMinutes = Math.round(hours * 60);
      return `${totalMinutes} m`;
    }

    return `${fullHours} h ${remainingMinutes} m`;
  }
}
