import {TuiDay, TuiTime} from '@taiga-ui/cdk';

export function dateToTuiDayTime (date?: Date): [TuiDay, TuiTime] | null {
  if (!date) {
    return null;
  }

  const nDate = new Date(date);
  return [new TuiDay(nDate.getFullYear(), nDate.getMonth(), nDate.getDate()), new TuiTime(nDate.getHours(), nDate.getMinutes())];
}
