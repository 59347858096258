@if (firstLoading) {
  <app-table-skeleton [isHideSearch]="true" [buttonsSize]="['w-2rem', 'w-8rem']" />
} @else {
  <app-table
      [tableConfig]="tableCfg"
      [tableRows]="tableData"
      [isLoading]="tableLoading"
      [isBlur]="isRefresh"
      [controlIsHideSearch]="true"
      [controlButton]="tableControls"
      [pagination]="dataPagination"
      (changePage)="onChangePagination($event)"
      (changePageSize)="onChangePageSize($event)"
  />
}
