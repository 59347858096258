<app-page-wrapper
    [customTitle]="employeeData?.label ?? 'Employee'"
    [isLoading]="isLoading"
>
  <control-panel>
    <tui-tabs-with-more class="w-max">
      @for (route of routes; track route.url) {
        <a
            *tuiItem
            routerLinkActive="active"
            tuiTab
            [routerLink]="route.url"
        >
          {{ route.label }}
        </a>
      }
    </tui-tabs-with-more>
  </control-panel>

  <main-content>
    <router-outlet />
  </main-content>
</app-page-wrapper>
