import 'reflect-metadata';
import {Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {DirectoryItem} from '@/app/entities/common/directory-item.entity';

export class TableConfig extends Entity  {
  tableInitialLoadTitle: string | null = 'Загружаем данные';
  tableLoadMoreTitle: string | null = 'Загружаем данные';
  tableLoadMoreButtonTitle: string | null = 'Загрузить еще';
  stickLeft: number = 0;
  stickRight: number = 0;
  isStickHeader: boolean = true;
  isStickFooter: boolean = true;
  isColumnConfigurable: boolean = true;

  @Type(() => DirectoryItem)
  columnGroup: DirectoryItem[];
}
