import 'reflect-metadata';
import {Type} from 'class-transformer';
import {TableHtmlElementAttributes} from '@/app/entities/table/table-html-element-attributes.entity';
import {Entity} from '@/app/core/entity/main.entity';

export class TableRowsConfig extends Entity {
  @Type(() => TableRowsActionAvailable)
  actionAvailable: TableRowsActionAvailable[];

  @Type(() => TableRowColsConfig)
  cols: TableRowColsConfig[];

  @Type(() => TableHtmlElementAttributes)
  htmlElementAttributesSchema: TableHtmlElementAttributes;
}

export class TableRowsActionAvailable extends Entity {
  method: string;
  methodSchema: string;
  titleSchema: string;
  iconSchema: string;
  availableSchema: string;
  tooltipSchema: string;

  @Type(() => TableRowsActionAvailable)
  subAction: TableRowsActionAvailable[];
}

export class TableRowColsConfig extends Entity {
  slug: string;
  variableSchema: string;

  @Type(() => TableHtmlElementAttributes)
  htmlElementAttributesSchema: TableHtmlElementAttributes;
}
