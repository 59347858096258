import { Component } from '@angular/core';
import {injectContext} from '@taiga-ui/polymorpheus';
import {TuiPopover} from '@taiga-ui/cdk';
import {TuiAlertOptions, TuiButton} from '@taiga-ui/core';
import {IAlert} from '@/app/alerts/alert/alert.interface';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [
    TuiButton
  ],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  protected readonly context = injectContext<TuiPopover<TuiAlertOptions<IAlert>, boolean>>();
  data = this.context.data;
}
