import {Inject, Injectable} from '@angular/core';
import {ParentModalService} from '@/app/modals/service/parent-modal.service';
import {IModalData, ModalType} from '@/app/modals/type/modal';
@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(
    @Inject(ParentModalService) private readonly parentModalService: ParentModalService,
  ) { }

  open(data: ModalType<IModalData<any>>) {
    this.parentModalService.open(null, data)
      .subscribe(response => {
        console.log({response})
      });
  }
}
