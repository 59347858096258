import 'reflect-metadata';
import {TableHeader} from '@/app/entities/table/table-header.entity';
import {Type} from 'class-transformer';
import {TableFooter} from '@/app/entities/table/table-footer.entity';
import {TableRowsConfig} from '@/app/entities/table/table-rows-config.entity';
import {TableConfig} from '@/app/entities/table/table-config.entity';
import {TableUserConfig} from '@/app/entities/table/table-user-config.entity';
import {Entity} from '@/app/core/entity/main.entity';

export class Table extends Entity {
  @Type(() => TableHeader)
  header: TableHeader[];

  @Type(() => TableFooter)
  footer: TableFooter[];
  dataGraph: any;

  @Type(() => TableRowsConfig)
  rowsConfiguration: TableRowsConfig;

  @Type(() => TableConfig)
  tableConfiguration: TableConfig;

  @Type(() => TableUserConfig)
  userConfiguration: TableUserConfig;
}
