@if (firstLoading) {
  <app-table-skeleton />
} @else {
  <app-table
      [tableConfig]="tableCfg"
      [tableRows]="tableData"
      [isLoading]="tableLoading"
      [isVirtual]="true"
      [controlButton]="tableButtons"
  />
}

<ng-template #tableDateFilter>
  <app-table-date-filter (onDateChange)="onDateChange($event)" />
</ng-template>
