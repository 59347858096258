import {TreeNode} from '@/app/entities/common/tree-node.entity';
import {Node} from '@/app/entities/nodes/node.entity';
import {TableCalculation} from '@/app/entities/table/table-calculation.entity';

export const transformTreeNodeToTableCalculation =
  (nodes: TreeNode<Node>[], withPrice: boolean = false): TreeNode<any>[] =>
  {
    return nodes.reduce((acc: TreeNode<any>[], item) => {
      const newItem: TreeNode<any> = {...item};
      const calculation = newItem.data.calculationsSelf || newItem.data.calculationParent;
      newItem.data = {
        ...newItem.data,
        profit: <TableCalculation>{
          calculation,
          isSelf: newItem.data.calculationSelf ? true : false,
          isTime: false,
        },
        timeSpent: <TableCalculation>{
          calculation,
          isSelf: newItem.data.calculationSelf ? true : false,
          isTime: true,
        },
        ...withPrice && {
          price: newItem.data.settingsSelf ?? newItem.data.settingsParent,
        }
      }

      if (newItem.children && newItem.children.length) {
        newItem.children = transformTreeNodeToTableCalculation(newItem.children);
      }

      acc.push(newItem);

      return acc;
    }, [])
  }
