import {Component, CUSTOM_ELEMENTS_SCHEMA, inject, signal} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {TableData} from '@/app/entities/table/table-data.entity';
import {ActivatedRoute} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DictionaryService} from '@/app/services/api/dictionary.service';
import {
  ITableControlButton,
  TableControlsComponent
} from '@/app/components/table-components/table-controls/item.component';
import {ModalService} from '@/app/modals/service/modal.service';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IButtonModalData} from '@/app/modals/type/modal';
import {Form} from '@/app/entities/form/form.entity';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {isDataList} from '@/app/entities/guards/isDataList.guard';
import {TableAction} from '@/app/entities/table/table-action.entity';

@Component({
  selector: 'app-dictionary-type-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    TableComponent,
    TableControlsComponent,
    TableSkeletonComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DictionaryTypePageComponent {
  aRoute = inject(ActivatedRoute);
  dictionaryService = inject(DictionaryService);
  modalService = inject(ModalService);

  types = new Map([
    ['position', {
      one: 'Position',
      many: 'Positions',
    }],
    ['department', {
      one: 'Department',
      many: 'Departments',
    }],
    ['transaction_category', {
      one: 'Transaction category',
      many: 'Transaction categories',
    }],
  ])

  currentType: string;

  loading = signal(true);
  firstLoading = true;

  tableLoading = false;

  tableCfg: Table;

  tableData: TableData[] = [];

  tableButtons: ITableControlButton[] = [];

  modalFormCfg: Form;

  constructor() {
    this.aRoute.params.pipe(
      takeUntilDestroyed()
    ).subscribe(params => {
      this.currentType = params['type'] ?? 'position';

      this.modalFormCfg = plainToInstance(Form, {
        fields: [
          {
            slug: 'dictionaries',
            elementConfiguration: {
              type: 'repeater',
              configuration: {
                fields: [
                  {
                    slug: 'title',
                    elementConfiguration: {
                      title: this.types.get(this.currentType)?.one ?? '',
                      type: 'text',
                      configuration:  {
                        type: 'text',
                        clearable: true,
                      },
                      validation: [
                        {
                          type: 'required',
                          text: 'Required field'
                        }
                      ],
                    }
                  },
                  // {
                  //   slug: 'related',
                  //   elementConfiguration: {
                  //     title: 'Related',
                  //     type: 'number',
                  //     configuration:  {
                  //       type: 'number',
                  //       clearable: true,
                  //       between: false,
                  //       minValue: 0,
                  //     },
                  //   }
                  // },
                ],
              }
            }
          }
        ],
        value: {
          dictionaries: [
            {
              title: '',
            }
          ]
        }
      });

      this.tableCfg = plainToInstance(Table, {
        "header": [
          {
            "title": this.types.get(this.currentType)?.one,
            "slug": "label",
            action: <TableAction>{
              buttons: [
                {
                  label: 'Delete',
                  onClick: (id) => {
                    if (id) {
                      this.loading.set(true);
                      this.dictionaryService.delete([id]).subscribe(res => {
                        if (res.isSuccess) {
                          this.loadData();
                        } else {
                          this.loading.set(false);
                        }
                      });
                    }
                  }
                }
              ]
            }
          },
          {
            "title": "Related employees",
            "slug": "related",
          },
        ],
      });

      this.tableButtons = [
        {
          button: {
            icon: '@tui.plus',
            appearance: 'outline',
            label: 'New ' + this.types.get(this.currentType)?.one?.toLowerCase(),
            disabled: this.loading,
            onClick: () => this.openNewDictionaryModal()
          }
        }
      ]

      this.loadData()
    })
  }

  loadData(search: string = '') {
    this.loading.set(true);
    this.tableLoading = true;
    this.dictionaryService.get(
      {
        id: [],
        type: [this.currentType],
      },
      {
        search,
        isDataList: true
      }
    ).subscribe({
      next: res => {
        if (res.isSuccess) {
          this.tableData = res.result.items.map((item => {
            const label = isDataList(item) ? item.label : item.title;

            return {
              data: {
                label: label,
                related: 0,
                id: item.id
              }
            }
          }))
        }

        this.loading.set(false);
        this.firstLoading = false;
        this.tableLoading = false;
      },
      error: err => {
        this.loading.set(false);
        this.firstLoading = false;
        this.tableLoading = false;
      }
    })
  }

  get title(): string {
    return `Dictionary: ${this.types.get(this.currentType)?.many}`
  }

  onSearch(search: string | null) {
    search = search ?? '';
    this.loadData(search);
  }

  openNewDictionaryModal() {
    this.modalService.open({
      dynamicComponent: CommonModalComponent,
      size: 'large',
      data: {
        title: 'New ' + this.types.get(this.currentType)?.one?.toLowerCase(),
        form: this.modalFormCfg,
        isLoading: this.loading,
        buttons: [
          {
            settings: {
              title: 'Cancel',
              appearance: 'flat',
            },
            isClose: true,
          },
          {
            settings: {
              title: 'Save',
              appearance: 'primary',
            },
            isClose: true,
          }
        ],
        callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
          if (btn.settings.title === 'Cancel') {
            return true;
          }

          modal.makeAllFormTouched = true;
          modal.formElement.getValueWithValidData();

          if (modal.formElement.formGroup.invalid) {
            return false;
          }
          this.loading.set(true);
          const {dictionaries} = modal.form.value;
          const items = dictionaries.map(item => ({
            title: item.title,
            type: this.currentType
          }))

          this.dictionaryService.update(items).subscribe({
            next: res => {
              if (res.isSuccess) {
                modal.close();
                this.loadData();
              }
            },
            error: err => {
              this.loading.set(false);
            }
          })

          return false;
        },
      }
    });
  }
}
