@if(isRange) {
  <div [formGroup]="form" class="flex align-items-center gap-3">
    <tui-input-date-time
        class="flex-1"
        formControlName="from"
        [min]="minDate"
        [max]="hasDate(form.get('to')!.value) ? form.get('to')!.value : maxDate"
        [tuiTextfieldCleaner]="showClear"
    >
      {{label}} from
    </tui-input-date-time>
    <tui-input-date-time
        class="flex-1"
        formControlName="to"
        [min]="hasDate(form.get('from')!.value) ? form.get('from')!.value : minDate"
        [max]="maxDate"
        [tuiTextfieldCleaner]="showClear"
    >
      {{label}} to
    </tui-input-date-time>
  </div>

} @else {
    <tui-input-date-time
        [formControl]="control"
        [min]="minDate"
        [max]="maxDate"
        [tuiTextfieldCleaner]="showClear"
    >
      {{label}}
    </tui-input-date-time>
}
