<app-page-wrapper>
  <main-content>
    @if (firstLoading) {
      <app-table-skeleton />
    } @else {
      <app-table
          [isBlur]="isRefreshing"
          [tableConfig]="tableCfg"
          [tableRows]="tableData"
          [isLoading]="tableLoading"
          [pagination]="dataPagination"
          (onRefresh)="onRefresh()"
          (onSearch)="onSearch($event)"
          (changePage)="onChangePagination($event)"
          (changePageSize)="onChangePageSize($event)"
      />
    }
  </main-content>
</app-page-wrapper>
