import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, WritableSignal} from '@angular/core';
import {TuiAppearance, TuiAppearanceOptions, TuiButton} from '@taiga-ui/core';
import {TuiInputModule, TuiTextfieldControllerModule} from '@taiga-ui/legacy';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {CommonModule} from '@angular/common';

export interface ITableControlButton {
  button?: {
    icon?: string;
    label?: string;
    disabled?: WritableSignal<boolean>;
    appearance?: TuiAppearanceOptions['appearance'];
    onClick?: () => void;
  },
  template?: TemplateRef<any>;
}

@Component({
  selector: 'app-table-controls',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    TuiInputModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableControlsComponent implements OnInit, OnDestroy {
  @Input() buttons: ITableControlButton[] = [];

  @Input() isHideSearch = false;
  @Output() onSearch = new EventEmitter<string | null>();

  search = new FormControl('');
  sub: Subscription;

  ngOnInit() {
    this.sub = this.search
      .valueChanges
      .pipe(
        debounceTime(250)
      )
      .subscribe(v => {
        this.onSearch.emit(v);
      })
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  onClick(btn: ITableControlButton['button']) {
    if (btn?.onClick) {
      btn.onClick();
    }
  }
}
