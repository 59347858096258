import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Injector, OnChanges, OnDestroy,
  OnInit,
  Output, SimpleChanges, WritableSignal
} from '@angular/core';
import {FormElement, FormElementServerError} from '@/app/entities/form/form-element.entity';
import {AbstractControl, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {BaseFormComponent} from '@/app/components/form-components/form-constructor/base/base-form.component';
import {Subscription} from 'rxjs';
import {FormArrayGroupModule} from '@/app/components/form-components/form-constructor/form-array-group.module';


/**
 * This component is used to create and manage formConstructor, handle form validations, and handle form values.
 *
 * @example
 * # Usage
 * ```html
 * <app-form-element
 *  #formElement
 *  [formControl]="formEl"
 *></app-form-element>
 * ```
 *
 * ```ts
 * @ViewChild('formElement') formElement: FormElementComponent;
 * ...
 * // A Form is an instance of a Form
 * const form = plainToInstance(Form, {fields})
 *
 * this.formElement.build(form.fields, null);
 * ```
 * To change the form you need to call {@link build} again
 *
 * If you need to add values for form fields, then pass them as the second parameter to {@link build}
 *
 * To get validated values you need to call {@link getValueWithValidData}
 *
 * If you need to check for form validation, you need to change the makeAllFormTouched parameter from False to True
 *
 */
@Component({
  selector: 'app-form-element',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    FormArrayGroupModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormElementComponent),
      multi: true
    },
  ]
})
export class FormElementComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy {

  @Output() override additionalFormIsInvalid = new EventEmitter<boolean>();
  formGroup: FormGroup;
  subs: Subscription[] = [];
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }


  ngOnInit() {

  }


  /**
   * Method called whenever there are changes detected in the input properties bound to this component.
   * It checks if the 'makeAllFormTouched' property has changed and performs validation on all form fields if necessary.
   *
   * @param {SimpleChanges} changes - An object containing the changed properties and their previous and current values.
   */
  ngOnChanges(changes: SimpleChanges) {
    const {makeAllFormTouched} = changes;
    if (makeAllFormTouched?.currentValue && makeAllFormTouched.currentValue !== makeAllFormTouched.previousValue) {
      this.validateAllFormFields(this.formGroup);
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }


  /**
   * Builds a form group based on the provided form elements and values.
   *
   * @param {FormElement[]} fields - The array of form elements.
   * @param {any} values - The values to be assigned to the form elements (optional).
   *
   * @example
   * Called via @ViewChild FormElementComponent to build the form via formConfiguration
   */
  build(fields: FormElement[], values = null) {
    let changedValue = null;
    if (this.formGroup && this.formGroup.controls) {
      this.formGroup.reset();
      changedValue = {
        ...this.formGroup.value,
        additionalForm:this.formGroup.value
      };
      const keys = Object.keys(this.formGroup.controls);
      keys.forEach(key => {
        this.formGroup.get(key)!.setValue(null);
        this.formGroup.removeControl(key);
      });
      this.cdRef.detectChanges();
    }

    this.formGroup = this.formBuilder.group({});
    this.buildFormGroup(this.formGroup, fields);
    this.cdRef.detectChanges();
    if (values && !changedValue) {
      this.addValues(values);
    } else if (changedValue){
     setTimeout(() => {
       this.addValues(changedValue);
       this.cdRef.detectChanges();
     })
    }

    this.subs.push(this.formGroup.valueChanges
      .pipe(
        debounceTime(300),
      ).subscribe(v => {
        this.additionalFormIsInvalid.emit(this.formGroup.invalid);
        if (this.onChangeModel) {
          this.onChangeModel(v);
        }
      }))
  }


  /**
   * Adds values to the form group recursively.
   *
   * @param {Object} values - The values to be added to the form group.
   */
  addValues(values: any) {
    if (values!==null) {
      this._addRepeatersRecursively(values, this.formGroup);

      this.formGroup.patchValue(values);
    }
  }

  /**
   * Sets server errors for the form controls based on the provided error object.
   *
   * @param {any} errors - the error object containing server errors
   * @return {void}
   */
  setServerErrors(errors: any) {
    Object.keys(errors).forEach(key => {
      const control = this.formGroup.controls[key];
      if (control instanceof FormGroup) {
        Object.keys(errors[key]).forEach(subKey => {
          (control.controls[subKey] as any).serverErrors = errors[key][subKey] as FormElementServerError[];
          this._update(control.controls[subKey]);
        });
      } else if (control) {
        (control as any).serverErrors = errors[key] as FormElementServerError[];
        this._update(control);
      }
    });
  }

  private _update(control: AbstractControl) {
    const field = control['field'] as WritableSignal<any>;
    field.update(fields => {
      return {
        ...fields,
        update: true
      }
    })
  }

  /**
   * Recursively adds repeaters to a form group based on nested values.
   *
   * @param {Object} values - The nested values object.
   * @param {FormGroup} formGroup - The form group to add repeaters to.
   */
  private _addRepeatersRecursively(values: any, formGroup: any) {
    for (const [key, value] of Object.entries(values)) {
      this._addRepeatersFromValues(value, formGroup.get(key));
      if (formGroup.get(key) && formGroup.get(key)['field']()?.elementConfiguration.type==='group') {
        this._addRepeatersRecursively(value, formGroup.get(key));
      }
    }
  }


  /**
   * Adds repeaters from a given array of values to a control.
   *
   * @param {*} value - The array of values to add as repeaters.
   * @param {*} fromControl - The control to add the repeaters to.
   */
  private _addRepeatersFromValues(value, fromControl) {
    if (fromControl && fromControl['field']()?.elementConfiguration?.type === 'repeater' && (value as []).length) {
      (value as []).forEach((item) => {
        const newGroup = this.addGroup(fromControl);
        this._addRepeatersRecursively(item, newGroup);
      });
    }
  }


  /**
   * Gets the final value for the form, including hidden fields
   *
   * @param {FormGroup | FormArray | any} formGroup - Only for recursive. Defaults to null.
   * @return {object} - Filtered form values.
   *
   * @example
   *
   * ```ts
   * @ViewChild('formElement') formElement: FormElementComponent;
   *...
   * this.formElement.getValueWithValidData();
   * ```
   */
  getValueWithValidData(formGroup: FormGroup | FormArray | any = null) {
    let value = {};
    const group = formGroup ? formGroup : this.formGroup;
    Object.keys(group?.controls ?? []).forEach(field => {
      const control = group.get(field);
      if (control instanceof FormControl) {
        if (control['field']().isShow) {
          value = {...value, [field]: control.value};
        }
      } else {
        const subValue = this.getValueWithValidData(control);
        if (control instanceof FormGroup) {
          value =  {...value, [field]: subValue};
        }
        if (control instanceof FormArray) {
          value = {...value, [field]: Object.values(subValue)};
        }
      }
    });

    return value;
  }
}
