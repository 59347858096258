<app-page-wrapper
  [buttons]="buttons"
>
  <control-panel>
    <p>Control panel</p>
  </control-panel>

  <main-content>
    <p>Main content</p>
  </main-content>
</app-page-wrapper>
