<div class="page">
  <div class="flex justify-content-between align-items-center mb-3">
    <div class="flex-1 flex align-items-center gap-2" [tuiSkeleton]="isLoading">
      <h2 class="page-title">{{customTitle || routeData.pageTitle}}</h2>
      @if (routeData.description || customDescription) {
        <tui-icon
            icon="@tui.info"
            [style.color]="'var(--text-color-secondary)'"
            [style.height.px]="16"
            [style.width.px]="16"
            [tuiDropdown]="customDescription ?? routeData.description"
            tuiDropdownHover
        />
      }
    </div>
<!--    <button-->
<!--        tuiButton-->
<!--        appearance="flat"-->
<!--        size="s"-->
<!--    >-->
<!--      Sync-->
<!--    </button>-->
  </div>

  <div class="flex justify-content-between align-items-center mb-3">
    <div class="flex-1">
      <ng-content select="control-panel"></ng-content>
    </div>

    <div class="flex gap-2">
      @for (btn of buttons; track btn.title) {
        <button
          tuiButton
          [iconStart]="btn.icon ?? ''"
          [appearance]="btn.appearance ?? 'outline'"
          size="s"
          (click)="onClick(btn)"
        >
          {{btn.title}}
        </button>
      }
    </div>
  </div>

  <ng-content select="main-content"></ng-content>
</div>

