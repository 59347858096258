<div [formGroup]="form">
  <div class="text-sm mb-3">{{label}}</div>
  @for (slug of objectKeys(form.controls); track slug) {
    <label class="flex align-items-center gap-2 mt-2 last w-max max-w-full">
      <input
          tuiCheckbox
          [indeterminate]="false"
          type="checkbox"
          [formControl]="getControl(slug)"
      />
      {{ getLabel(slug) }}
    </label>
  }
</div>
