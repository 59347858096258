import {NgModule} from '@angular/core';
import {FormArrayComponent} from '@/app/components/form-components/form-constructor/form-array/item';
import {FormGroupComponent} from '@/app/components/form-components/form-constructor/form-group/item';
import {FormControlComponent} from '@/app/components/form-components/form-constructor/form-control/item';
import {ReactiveFormsModule} from '@angular/forms';
import {TuiButton} from '@taiga-ui/core';

@NgModule({
  imports: [
    FormControlComponent,
    ReactiveFormsModule,
    TuiButton
  ],
  exports: [
    FormGroupComponent
  ],
  declarations: [FormArrayComponent, FormGroupComponent]
})
export class FormArrayGroupModule {
}
