import {Component, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {PageWrapperButton} from '@/app/types/page/page-wrapper-button.type';

@Component({
  selector: 'app-blank-page',
  standalone: true,
  imports: [
    PageWrapperComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BlankPageComponent {
  buttons: PageWrapperButton[] = [
    {
      title: 'Edit project',
      icon: '@tui.pencil'
    },
    {
      title: 'Delete project',
      icon: '@tui.trash-2'
    }
  ];
}
