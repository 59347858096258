/* eslint-disable @typescript-eslint/naming-convention */
import 'reflect-metadata';
import {Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {FormElement} from '@/app/entities/form/form-element.entity';

export class Form extends Entity {
  id?: number; // non exist form
  fieldId?: number; // exist form
  value?: any = null;

  title?: string;
  urlToSubmit: string | null = null;
  urlFromLoad: string | null = null;

  @Type(() => FormElement)
  fields: FormElement[] = [];

  getId?() {
    return this.isExist && this.isExist() ? this.fieldId : this.id;
  }

  isExist?() {
    return typeof this.fieldId !== 'undefined' && !!this.fieldId;
  }
}
