import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  inject,
  OnInit,
  signal,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {
  ITableControlButton,
} from '@/app/components/table-components/table-controls/item.component';
import {Form} from '@/app/entities/form/form.entity';
import {plainToInstance} from 'class-transformer';
import {ModalService} from '@/app/modals/service/modal.service';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {NodeService} from '@/app/services/api/node.service';
import {DictionaryService} from '@/app/services/api/dictionary.service';
import {combineLatest} from 'rxjs';
import {DataList} from '@/app/entities/common/data-list.entity';
import {IButtonModalData} from '@/app/modals/type/modal';
import {TransactionService} from '@/app/services/api/transaction.service';
import {CustomDate} from '@/app/core/classes/custom-date';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {TableDateFilterComponent} from '@/app/components/table-components/table-date-filter/item.component';
import {DateRange} from '@/app/entities/common/date-range.entity';
import {ApiTransactionFilter, ApiTransactionModifier} from '@/app/entities/api/types/api-transaction.type';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';

@Component({
  selector: 'app-transaction-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    TableComponent,
    TableDateFilterComponent,
    TableSkeletonComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TransactionPageComponent implements OnInit, AfterViewInit {
  @ViewChild('dateFilter', {read: TemplateRef}) dateFilter: TemplateRef<any>;

  modalService = inject(ModalService);
  nodeService = inject(NodeService);
  dictionaryService = inject(DictionaryService);
  transactionService = inject(TransactionService);
  cdr = inject(ChangeDetectorRef);

  modalFormCfg: Form;
  loading = signal(true);
  tableLoading = false;
  firstLoading = true;



  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Date",
        "slug": "addedAt",
      },
      {
        "title": "Title",
        "slug": "title",
      },
      {
        "title": "Description",
        "slug": "description",
      },
      {
        "title": "Category",
        "slug": "category",
      },
      {
        "title": "Project",
        "slug": "project",
      },
      {
        "title": "Issue",
        "slug": "issue",
      },
      {
        "title": "Amount",
        "slug": "amount",
        "columnType": TableColumnType.finances
      },
    ],
  });

  tableData: TableData[] = [];

  tableButtons: ITableControlButton[] = [];
  isRefreshing = false;

  tablePagination: ApiResultPagination;
  tableFilter?: Partial<ApiTransactionFilter>;
  tableModifier?: Partial<ApiTransactionModifier>;

  ngOnInit() {
    this.getTransactions({
      perPage: 40,
      currentPage: 1
    });
  }

  ngAfterViewInit() {
    this.tableButtons  = [
      {
        button: {
          icon: '@tui.plus',
          appearance: 'outline',
          label: 'New transaction',
          disabled: this.loading,
          onClick: () => this.openNewTransactionModal()
        },
      },
      {
        template: this.dateFilter
      },
      {
        button:  {
          icon: '@tui.refresh-cw',
          appearance: 'outline',
          disabled: this.loading,
          onClick: () => {
            this.isRefreshing = true;
            this.getTransactions({
              currentPage: this.tablePagination.currentPage,
              perPage: this.tablePagination.perPage < 8 ? 8 : this.tablePagination.perPage
            });
          }
        },
      }
    ];
    this.cdr.detectChanges();
  }

  getTransactions(
    pagination?: ApiPagination
  ) {
    this.loading.set(true);
    this.tableLoading = true;
    pagination = pagination ? {
      ...pagination,
      perPage: pagination.perPage < 8 ? 8 : pagination.perPage
    } : undefined;
    this.transactionService.get(this.tableFilter, this.tableModifier, pagination).subscribe({
      next: res => {
        if (res.isSuccess) {
          this.tableData = res.result.items.map(item => ({
            data: {
              addedAt: new CustomDate(item.addedAt).format(),
              title: item.title,
              description: item.description,
              category: item.category?.title ?? '',
              project: item.node?.title ?? '',
              issue: item.node?.code ?? '',
              amount: item.amount,
            }
          }));

          if (res.result.pagination) {
            this.tablePagination = res.result.pagination;
          }
        }
        this.tableLoading = false;
        this.firstLoading = false;
        this.isRefreshing = false;
        this.loading.set(false);
      },
      error: err => {
        this.tableLoading = false;
        this.firstLoading = false;
        this.isRefreshing = false;
        this.loading.set(false);
      }
    })
  }

  onChangePagination(data: ApiPagination) {
    this.tableLoading = true;
    this.getTransactions({
      currentPage: data.currentPage+1,
      perPage: data.perPage
    })
  }

  onChangePageSize(size: number) {
    this.tableLoading = true;
    this.getTransactions({
      currentPage: 1,
      perPage: size
    })
  }

  onDateChange(date: DateRange | null) {
    this.tableFilter = date ? {
      id: [],
      addedAt: date
    } : undefined;
    this.getTransactions({
      currentPage: 1,
      perPage: 40
    })
  }

  onSearch(value: string | null) {
    this.tableModifier = value ? {
      search: value
    } : undefined;

    this.getTransactions({
      currentPage: 1,
      perPage: 40
    })
  }

  openNewTransactionModal() {
    this.loading.set(true);
    combineLatest([
      this.nodeService.get({
        isProject: true
      }, {
        isDataList: true,
        addChildrens: false
      }),
      this.dictionaryService.get({
        id: [],
        type: ['transaction_category'],
      }, {
        isDataList: true
      })
    ]).subscribe(([nodeRes, dictionaryRes]) => {
      if (nodeRes.isSuccess && dictionaryRes.isSuccess) {
        this.modalFormCfg = plainToInstance(Form, {
          fields: [
            {
              slug: 'type',
              elementConfiguration: {
                type: 'radio',
                configuration: {
                  direction: 'row',
                  dataList: <DataList[]>[
                    {
                      id: 'outcome',
                      label: 'Outcome',
                    },
                    {
                      id: 'income',
                      label: 'Income',
                    }
                  ],
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'amount',
              elementConfiguration: {
                title: 'Amount',
                type: 'number',
                configuration: {
                  type: 'number',
                  between: false,
                  minValue: 0,
                  clearable: true,
                  prefix: '$ '
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'date',
              elementConfiguration: {
                title: 'Date',
                type: 'date',
                configuration:  {
                  between: false,
                  clearable: true,
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'title',
              elementConfiguration: {
                title: 'Title',
                type: 'text',
                configuration:  {
                  type: 'text',
                  clearable: true,
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'description',
              elementConfiguration: {
                title: 'Description',
                type: 'textarea',
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              },
            },
            {
              slug: 'categoryId',
              elementConfiguration: {
                title: 'Category',
                type: 'select',
                configuration: {
                  clearable: true,
                  search: false,
                  dataList: dictionaryRes.result.items
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            },
            {
              slug: 'nodeId',
              elementConfiguration: {
                title: 'Project/Issue',
                type: 'select',
                configuration: {
                  clearable: true,
                  search: false,
                  dataList: nodeRes.result.items
                },
                validation: [
                  {
                    type: 'required',
                    text: 'Required field'
                  }
                ],
              }
            }
          ]
        })

        this.modalService.open({
          dynamicComponent: CommonModalComponent,
          size: 'large',
          data: {
            title: 'New transaction',
            form: this.modalFormCfg,
            isLoading: this.loading,
            buttons: [
              {
                settings: {
                  title: 'Cancel',
                  appearance: 'flat',
                },
                isClose: true,
              },
              {
                settings: {
                  title: 'Save',
                  appearance: 'primary',
                },
                isClose: true,
              }
            ],
            callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
              if (btn.settings.title === 'Cancel') {
                return true;
              }

              modal.makeAllFormTouched = true;
              modal.formElement.getValueWithValidData();

              if (modal.formElement.formGroup.invalid) {
                return false;
              }

              const {date, ...value} = modal.form.value;

              this.loading.set(true);
              this.transactionService.update([{
                ...value,
                addedAt: (new CustomDate(date)).format('yyyy-MM-dd'),
              }]).subscribe({
                next: res => {
                  if (res.isSuccess) {
                    modal.close();
                    this.getTransactions(this.tablePagination)
                  } else {
                    this.loading.set(false);
                  }
                },
                error: err => {
                  this.loading.set(false);
                }
              })

              return false;
            },
          }
        });
      }

      this.loading.set(false);
    })
  }
}
