import {AfterViewInit, Component, inject, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NodeService} from '@/app/services/api/node.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {
  ITableControlButton,
} from '@/app/components/table-components/table-controls/item.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {TableDateFilterComponent} from '@/app/components/table-components/table-date-filter/item.component';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {DateRange} from '@/app/entities/common/date-range.entity';

@Component({
  selector: 'app-employee-issues-page',
  standalone: true,
  imports: [
    TableSkeletonComponent,
    TableComponent,
    TableDateFilterComponent
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class EmployeeIssuesPageComponent implements AfterViewInit {
  @ViewChild('tableDateFilter', {read: TemplateRef}) tableDateFilter: TemplateRef<any>;

  aRoute = inject(ActivatedRoute);
  nodeService = inject(NodeService);

  employeeId: number;
  tableLoading = true;
  firstLoading = true;
  isRefresh  = false;

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Project/Issue",
        "slug": "code",
      },
      {
        "title": "Title",
        "slug": "title",
      },
      {
        "title": "Calculations Self",
        "slug": "calculationsSelf",
        "columnType": TableColumnType.progress
      },
      {
        "title": "Calculations Parent",
        "slug": "calculationsParent",
        "columnType": TableColumnType.progress
      },
    ],
  });
  tableData: TableData[] = [];
  tableButtons: ITableControlButton[] = [];

  constructor() {
    this.aRoute.parent?.params
      .pipe(
        takeUntilDestroyed()
      ).subscribe(params => {
        if (params['employeeId']) {
          this.employeeId = params['employeeId'];
        }

        this.loadNodes();
    })
  }

  ngAfterViewInit() {
    this.tableButtons  = [
      {
        template: this.tableDateFilter
      },
      {
        button:  {
          icon: '@tui.refresh-cw',
          appearance: 'outline',
        },
      }
    ];
  }

  loadNodes() {
    this.tableLoading = true;

    this.nodeService.get({
      isProject: true
    }, {
      employeeId: [this.employeeId],
      treeNode: true,
    }).subscribe(res => {
      console.log({res});

      if (res.isSuccess) {
        this.tableData = res.result.items as unknown as TableData[];
      }

      this.tableLoading = false;
      this.firstLoading = false;
      this.isRefresh = false;
    })
  }

  onDateChange(date: DateRange | null) {

  }
}
