<tui-textfield>
  <label tuiLabel>{{label}}</label>
  <input
      tuiTextfield
      type="password"
      [formControl]="control"
  />

  <tui-icon tuiPassword />
</tui-textfield>
