import {Injectable} from '@angular/core';
import {ModalType} from '@/app/modals/type/modal';
import {TuiPopoverService} from '@taiga-ui/cdk';
import {TUI_DIALOGS} from '@taiga-ui/core';
import {ParentModalComponent} from '@/app/modals/parent-modal/item';

@Injectable({
  providedIn: 'root',
  useFactory: () => {
    return new ParentModalService(TUI_DIALOGS, ParentModalComponent);
  }
})
export class ParentModalService extends TuiPopoverService<ModalType<any>, any> {}
