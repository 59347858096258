import {Component, inject, signal} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {EmployeeContractService} from '@/app/services/api/employee-contract.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApiPagination, ApiResultPagination} from '@/app/entities/api/api-pagination.entity';
import {TableSkeletonComponent} from '@/app/skeleton/table-skeleton/table-skeleton.component';
import {TableComponent} from '@/app/components/table-components/table/item.component';
import {
  ITableControlButton,
} from '@/app/components/table-components/table-controls/item.component';
import {plainToInstance} from 'class-transformer';
import {Table} from '@/app/entities/table/table.entity';
import {TableColumnType} from '@/app/entities/table/table-header.entity';
import {TableData} from '@/app/entities/table/table-data.entity';
import {ModalService} from '@/app/modals/service/modal.service';
import {CommonModalComponent} from '@/app/modals/components/common/item';
import {IButtonModalData} from '@/app/modals/type/modal';
import {Form} from '@/app/entities/form/form.entity';
import {CustomDate} from '@/app/core/classes/custom-date';
import {TableAction} from '@/app/entities/table/table-action.entity';

@Component({
  selector: 'app-employee-contracts-page',
  standalone: true,
  imports: [
    TableSkeletonComponent,
    TableComponent,
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
})
export class EmployeeContractsPageComponent {
  aRoute = inject(ActivatedRoute);
  employeeContractService = inject(EmployeeContractService);
  modalService = inject(ModalService);

  dataPagination = <ApiResultPagination>{
    currentPage: 1,
    perPage: 40
  }

  modalFormCfg = plainToInstance(Form, {
    fields: [
      {
        slug: 'group',
        elementConfiguration: {
          type: 'group',
          configuration: {
            fields: [
              {
                slug: 'startAt',
                elementConfiguration: {
                  title: 'Start date',
                  type: 'date',
                  configuration:  {
                    between: false,
                    clearable: true,
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ],
                }
              },
              {
                slug: 'endAt',
                elementConfiguration: {
                  title: 'Termination date',
                  type: 'date',
                  configuration:  {
                    between: false,
                    clearable: true,
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ],
                }
              },
              {
                slug: 'paymentType',
                elementConfiguration: {
                  title: 'Contract type',
                  type: 'select',
                  configuration: {
                    clearable: true,
                    search: false,
                    dataList: [
                      {
                        id: 'rate',
                        key: 'rate',
                        label: 'Rate',
                      },
                      {
                        id: 'fix',
                        key: 'fix',
                        label: 'Fix',
                      },
                    ]
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ],
                }
              },
              {
                slug: 'salary',
                elementConfiguration: {
                  title: 'Contract amount',
                  type: 'number',
                  configuration: {
                    type: 'number',
                    between: false,
                    minValue: 0,
                    clearable: true,
                    prefix: '$ '
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ],
                },
                showIf: [
                  {
                    field: 'paymentType',
                    value: ['fix']
                  }
                ]
              },
              {
                slug: 'hourRate',
                elementConfiguration: {
                  title: 'Hour rate',
                  type: 'number',
                  configuration: {
                    type: 'number',
                    between: false,
                    minValue: 0,
                    clearable: true,
                    prefix: '$ '
                  },
                  validation: [
                    {
                      type: 'required',
                      text: 'Required field'
                    }
                  ],
                },
                showIf: [
                  {
                    field: 'paymentType',
                    value: ['rate']
                  }
                ]
              },
            ]
          },
          validation: [
            {
              type: 'dateRange',
              text: 'Error field',
              relationFrom: 'startAt',
              relation: 'endAt'
            }
          ],
        }
      },
    ]
  });

  employeeId: number;
  tableLoading = true;
  firstLoading = true;
  isRefresh  = false;

  isControlLoading = signal(false);

  tableControls: ITableControlButton[] = [
    {
      button:  {
        icon: '@tui.refresh-cw',
        appearance: 'outline',
        disabled: this.isControlLoading,
        onClick: () => {
          this.onRefresh();
        }
      },
    },
    {
      button: {
        icon: '@tui.plus',
        appearance: 'outline',
        label: 'Add contract',
        disabled: this.isControlLoading,
        onClick: () => {
          this.onModalOpen();
        }
      },
    }
  ];

  tableCfg = plainToInstance(Table, {
    "header": [
      {
        "title": "Start date",
        "slug": "startAt",
        "columnType": TableColumnType.date
      },
      {
        "title": "Termination date",
        "slug": "endAt",
        "columnType": TableColumnType.date
      },
      {
        "title": "Contract type",
        "slug": "paymentType",
      },
      {
        "title": "Contract amount",
        "slug": "amount",
        "columnType": TableColumnType.finances
      },
      {
        "title": "",
        "slug": "action",
        "columnType": TableColumnType.action
      },
    ],
  });
  tableData: TableData[] = [];

  constructor() {
    this.aRoute.parent?.params
      .pipe(
        takeUntilDestroyed()
      )
      .subscribe(params => {
        this.employeeId = params['employeeId'];

        this.loadContracts()
      })
  }

  onChangePagination(data: ApiPagination) {
    this.tableLoading = true;
    this.loadContracts({
      currentPage: data.currentPage+1,
      perPage: data.perPage
    })
  }

  onChangePageSize(size: number) {
    this.tableLoading = true;
    this.loadContracts({
      currentPage: 1,
      perPage: size
    })
  }

  onModalOpen() {
    this.modalService.open({
      dynamicComponent: CommonModalComponent,
      size: 'large',
      data: {
        title: 'Add contract',
        form: this.modalFormCfg,
        isLoading: this.isControlLoading,
        buttons: [
          {
            settings: {
              title: 'Cancel',
              appearance: 'flat',
            },
            isClose: true,
          },
          {
            settings: {
              title: 'Save',
              appearance: 'primary',
            },
            isClose: true,
          }
        ],
        callbackBeforeClose: (modal: CommonModalComponent, btn: IButtonModalData<CommonModalComponent>) => {
          if (btn.settings.title === 'Cancel') {
            return true;
          }

          modal.makeAllFormTouched = true;
          modal.formElement.getValueWithValidData();

          if (modal.formElement.formGroup.invalid) {
            return false;
          }

          const {salary, hourRate , startAt, endAt, paymentType} = modal.form.value?.group;
          this.isControlLoading.set(true);
          this.employeeContractService.update([
            {
              employeeId: this.employeeId,
              hourRate: paymentType === 'rate' ? hourRate : null,
              salary: paymentType === 'fix' ? salary : null,
              startAt: (new CustomDate(startAt)).format('yyyy-MM-dd'),
              endAt: (new CustomDate(endAt)).format('yyyy-MM-dd'),
              paymentType,
            }
          ]).subscribe({
            next: res => {
              if (res.isSuccess) {
                this.loadContracts({
                  currentPage: 1,
                  perPage: this.dataPagination.perPage
                });
                modal.close();
              }
            },
            error: err => {
              this.isControlLoading.set(false);
            }
          })

          return false;
        },
      }
    });
  }

  loadContracts(pagination: ApiPagination = {
    currentPage: this.dataPagination.currentPage,
    perPage: this.dataPagination.perPage,
  }) {
    this.tableLoading = true;
    this.isControlLoading.set(true);

    this.employeeContractService.get({
      id: [this.employeeId],
    }, pagination).subscribe({
      next: res => {

        if (res.isSuccess) {
          this.tableData = res.result.items.map((item) => ({
            data: {
              ...item,
              amount: item.paymentType === 'fix' ? item.salary : item.hourRate,
              action: <TableAction>{
                buttons: [
                  {
                    label: 'Delete',
                    onClick: () => {
                      console.log({item})
                    }
                  }
                ]
              }
            }
          }))

          if  (res.result.pagination) {
            this.dataPagination = {
              ...res.result.pagination,
              perPage: res.result.pagination.perPage < 8 ? 8 : res.result.pagination.perPage,
            };
          }
        }

        this.isRefresh = false;
        this.tableLoading = false;
        this.firstLoading = false;
        this.isControlLoading.set(false);
      },
      error: err => {
        this.isRefresh = false;
        this.tableLoading = false;
        this.firstLoading = false;
        this.isControlLoading.set(false);
      }
    })
  }

  onRefresh() {
    this.isRefresh = true;
    this.loadContracts();
  }
}
