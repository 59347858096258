
import {
  ChangeDetectionStrategy,
  Component, forwardRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from '@angular/forms';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';
import {BaseFormComponent} from '@/app/components/form-components/form-constructor/base/base-form.component';
import {Subscription} from 'rxjs';
import {FormControlComponent} from '@/app/components/form-components/form-constructor/form-control/item';
import {FormArrayComponent} from '@/app/components/form-components/form-constructor/form-array/item';

/**
 * This component is only used in FormArrayComponent and FormElementComponent to group FormControls
 *
 * @example
 * <app-form-group
 *   [makeAllFormTouched]="false"
 *   [formGroup]='control'
 * ></app-form-group>
 */
@Component({
  selector: 'app-form-group',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGroupComponent extends BaseFormComponent implements OnInit, OnChanges, OnDestroy{
  @Input() formGroup: FormGroup | any;
  @Input() isFirstGroup: boolean = false;

  private _isLoaded: boolean[] = [];
  subs: Subscription[] = [];
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }


  /**
   * This method checks and sets the validators for each field in the form group. It also subscribes to the valueChanges
   * observable of the form group, debounce the changes, and checks the fields for validity changes.
   */
  ngOnInit() {
    this.formSubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { formGroup } = changes;
    if (formGroup && formGroup.firstChange) {
      this.updateFieldsAndValidators();
    } else if (this.isFirstGroup && formGroup) {
      const currentControlFields = Object.values(formGroup.currentValue.controls).map((control: any) => ({
        ...control['field']()
      }));
      const previousControlFields = Object.values(formGroup.previousValue.controls).map((control: any) => ({
        ...control['field']()
      }));
      if (JSON.stringify(currentControlFields) !== JSON.stringify(previousControlFields)) {
        this.updateFieldsAndValidators();
        this.formSubscribe();
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  formSubscribe() {
    this.subs.push(this.formGroup.valueChanges
      .pipe(
        // debounceTime(300),
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        })
      ).subscribe(v => {
        Object.keys(v).forEach(key => {
          this.checkFields(this.formGroup, key);
        });
      }))


    const relatedControls: any[] = [];

    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      if (control['field']().currentElementConfiguration.configuration && control['field']().currentElementConfiguration.configuration['relatedField'] && control['field']().currentElementConfiguration.configuration.type === 'slug') {
        relatedControls.push(control);
      }
    })

    relatedControls.forEach((control, idx) => {
      const relatedControlKey = control['field']().currentElementConfiguration.configuration['relatedField'];
      const relatedControl = this.formGroup.get(relatedControlKey);

      if(relatedControl) {
        this.subs.push(relatedControl.valueChanges.pipe(
          tap(val => {
            if (val) {
              if (!this._isLoaded[idx]) {
                control.setValue(' ');
              }
              this._isLoaded[idx] = true;
            } else {
              this._isLoaded[idx] = false;
            }
          }),
          debounceTime(350),
          distinctUntilChanged(),
        ).subscribe(val => {
          control.setValue(val ?? '');
        }));
      }
    })
  }

  updateFieldsAndValidators() {
    Object.keys(this.formGroup.controls).forEach(key => {
      this.checkFields(this.formGroup, key);
      const fieldKeys = Object.keys(this.formGroup.controls);
      if (fieldKeys.length) {
        fieldKeys.forEach(key => {
          const item = this.formGroup.get(key);
          item.clearValidators();
          item.addValidators(this.getValidationArray(item, this.formGroup));
        });
      }
    });
  }
}
