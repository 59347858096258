<tui-root [attr.tuiTheme]="darkMode() ? 'dark' : null">
  <router-outlet />

  <button
      [iconStart]="darkMode() ? '@tui.sun' : '@tui.moon'"
      size="xs"
      tuiIconButton
      type="button"
      class="theme-button"
      [style.border-radius.%]="100"
      (click)="darkMode.set(!darkMode())"
  >
  </button>
</tui-root>
