import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input, OnDestroy, OnInit,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule} from '@angular/forms';
import {DataList} from '@/app/entities/common/data-list.entity';
import {AbstractNgModelComponent} from '@/app/core/abstract/ng-model.component';
import {Subscription} from 'rxjs';
import {SelectButtonModule} from 'primeng/selectbutton';


/**
 * The SegmentComponent class is a component that represents a button group.
 *
 * @example
 * <app-segment
 *   [formControl]='control'
 *   [options]="[]"
 * ></app-segment>
 *
 * @see options - for data
 */

@Component({
  selector: 'app-segment',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SelectButtonModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true
    }
  ]
})
export class SegmentComponent extends AbstractNgModelComponent implements OnInit, OnDestroy {
  /**
   * Option for selected button
   */
  @Input() options: DataList[] = [];

  control = new FormControl();

  data: any;
  sub: Subscription;
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.sub = this.control.valueChanges.subscribe(v => {
      if (this.onChangeModel) {
        this.onChangeModel(v);
      }
    })
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  override writeValue(value: any) {
    super.writeValue(value);
    if (value) {
      this.control.setValue(value, {emitEvent: false});
    } else {
      this.control.setValue(this.options[0].id, {emitEvent: false});
    }
  }
}
