<app-page-wrapper [customTitle]="title">
  <main-content>
    @if (firstLoading) {
      <app-table-skeleton [rowNumber]="3" [colNumber]="2" [buttonsSize]="['w-8rem']" />
    } @else {
      <app-table
          [tableConfig]="tableCfg"
          [tableRows]="tableData"
          [isLoading]="tableLoading"
          [controlButton]="tableButtons"
          (onSearch)="onSearch($event)"
      />
    }
  </main-content>
</app-page-wrapper>
