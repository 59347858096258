<p>{{ data?.text ?? '' }}</p>
<div class="flex gap-2 mt-2">
  @for (response of [true, false]; track $index) {
    <button
        appearance="whiteblock"
        size="s"
        tuiButton
        type="button"
        class="tui-space_right-1"
        (click)="context.completeWith(response)"
    >
      {{ response ? 'Ok' : 'Cancel' }}
    </button>
  }
</div>
