import 'reflect-metadata';
import {Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {TableHtmlElementAttributes} from '@/app/entities/table/table-html-element-attributes.entity';

export class TableFooter extends Entity {
  title: string;
  description: string | null = null;
  slug: string;

  @Type(() => TableHtmlElementAttributes)
  htmlElementAttributes: TableHtmlElementAttributes;
}
