import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';

const allowedTypes = ['position', 'department', 'transaction_category'];

export const allowedDictionaryTypesGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const type = route.paramMap.get('type');

  if (type && allowedTypes.includes(type)) {
    return true;
  }

  router.navigate(['/dictionaries']);
  return false;
};
