import {Component, signal} from '@angular/core';
import {TuiButton, TuiIcon} from '@taiga-ui/core';
import {RouteDataFull} from '@/app/types/page/route-data.type';
import {routeToFullData} from '@/app/utils/route/routeToFullData';
import {routes} from '@/app/app.routes';
import {RouterLink, RouterLinkActive} from '@angular/router';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    TuiIcon,
    TuiButton,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class MenuComponent {
  isOpen = signal(false);

  menuList: RouteDataFull[] = [];

  constructor() {
    this.menuList = routeToFullData(routes);
  }
}
