import {
  differenceInCalendarDays,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  formatISO,
  isAfter,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subMonths,
  isBefore,
  isSameDay,
  isSameHour,
  isSameMinute,
  differenceInMinutes,
  getDate,
  set,
  getYear,
  getMonth,
  addMinutes,
  addDays,
  startOfMinute
} from 'date-fns';
import {ru} from 'date-fns/locale';

export class CustomDate extends Date {

  static toCustomDate() {
    return new CustomDate();
  }

  static fromTime(time: number) {
    return new CustomDate(time);
  }

  // возможные форматы: https://date-fns.org/v2.29.3/docs/format
  format(newFormat = 'dd.MM.yyyy') {
    return this.getTime() === 0 ? '--.--.----' : format(this, newFormat, {locale: ru});
  }

  toIso(): string {
    return formatISO(this);
  }

  override toISOString(): string {
    return this.toIso();
  }

  override toString(): string {
    return this ? this.toIso() : '';
  }

  startOfMinute(): CustomDate {
    return startOfMinute(this).toCustomDate();
  }

  endOfDay(): CustomDate {
    return endOfDay(this).toCustomDate();
  }

  startOfDay(): CustomDate {
    return startOfDay(this).toCustomDate();
  }

  endOfWeek(): CustomDate {
    return endOfWeek(this, {weekStartsOn: 1}).toCustomDate();
  }

  startOfWeek(): CustomDate {
    return startOfWeek(this, {weekStartsOn: 1}).toCustomDate();
  }

  endOfMonth(): CustomDate {
    return endOfMonth(this).toCustomDate();
  }

  startOfMonth(): CustomDate {
    return startOfMonth(this).toCustomDate();
  }

  endOfYear(): CustomDate {
    return endOfYear(this).toCustomDate();
  }

  startOfYear(): CustomDate {
    return startOfYear(this).toCustomDate();
  }

  diffFromDate(date: CustomDate | Date): number {
    return differenceInCalendarDays(this, date) + 1;
  }

  diffFromNow(): number {
    return this.diffFromDate(new Date());
  }

  diffInDays(date: CustomDate | Date): number {
    return differenceInCalendarDays(this, date);
  }

  diffInMinutes(date): number {
    return differenceInMinutes(this, date);
  }

  minusMonth(value: number): CustomDate {
    return subMonths(this, value).toCustomDate();
  }

  isAfter(after: CustomDate): boolean {
    return isAfter(this, after);
  }

  isBefore(before: CustomDate): boolean {
    return isBefore(this, before);
  }

  isBetween(start: CustomDate, end: CustomDate): boolean {
    return start <= this && end >= this;
  }

  isToday(): boolean {
    return this.isSameDay(new CustomDate());
  }

  isSameDay(date: CustomDate): boolean {
    return isSameDay(this, date);
  }

  isSameHour(date: CustomDate): boolean {
    return isSameHour(this, date);
  }

  isSameMinute(date: CustomDate): boolean {
    return isSameMinute(this, date);
  }

  setDateNotTime(date): CustomDate {
    return set(this, {year: getYear(date), month: getMonth(date), date: getDate(date)}).toCustomDate();
  }

  addDays(days: number): CustomDate {
    return addDays(this, days).toCustomDate();
  }

  addMinutes(minutes: number): CustomDate {
    return addMinutes(this, minutes).toCustomDate();
  }
}
