import { NG_EVENT_PLUGINS } from "@taiga-ui/event-plugins";
import { provideAnimations } from "@angular/platform-browser/animations";
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import {provideRouter, TitleStrategy} from '@angular/router';

import { routes } from './app.routes';
import {TemplatePageTitleStrategy} from '@/app/strategies/template-page-title.strategy';
import {provideHttpClient, withInterceptors} from '@angular/common/http';
import {authInterceptor} from '@/app/interceptors/auth.interceptor';
import {messageInterceptor} from '@/app/interceptors/message.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(routes),
    importProvidersFrom(),
    provideHttpClient(
      withInterceptors([authInterceptor, messageInterceptor])
    ),
    NG_EVENT_PLUGINS,
    { provide: TitleStrategy, useClass: TemplatePageTitleStrategy },
  ]
};
