import {RouteData, RouteDataFull} from '@/app/types/page/route-data.type';
import {Routes} from '@angular/router';

export const routeToFullData = (routes: Routes, parentPath: string = ''): RouteDataFull[] => {
  const result: RouteDataFull[] = [];

  routes.forEach(route => {
    const currentPath = parentPath + (parentPath && route.path ? '/' : '') + route.path;
    if (!route.title && route.children) {
      result.push(...routeToFullData(route.children, currentPath));
    } else if (route.title) {
      const data = (route.data ?? {}) as RouteData;
      if (!data.isHidden) {
        const routeDataFull: RouteDataFull = {
          ...data,
          fullPath: currentPath,
        };

        if (route.children) {
          routeDataFull.children = routeToFullData(route.children, currentPath);
        }

        result.push(routeDataFull);
      }
    }
  });

  return result;
}
