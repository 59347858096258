import {TableData} from '@/app/entities/table/table-data.entity';

export function tableTotalLength(array: TableData[]): number {
  let length = 0;

  function countChildren(items: TableData[]): void {
    for (const item of items) {
      length += 1;
      if (Array.isArray(item.children)) {
        countChildren(item.children);
      }
    }
  }

  countChildren(array);
  return length;
}

export function tableTotalExpandedLength(array: (TableData & {expanded: boolean})[]): number {
  let count = 0;

  function countChildren(items: any): void {
    for (const item of items) {
      count += 1;

      if (count >= 10) {
        count = 10;
        return;
      }

      if (item.expanded && item.children) {
        countChildren(item.children);
      }
    }
  }

  countChildren(array);

  return count;
}
