import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '@/app/entities/api/api-response.entity';
import {ApiPagination} from '@/app/entities/api/api-pagination.entity';
import {environment} from '@/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiCoreService<T> {

  private apiUrl = environment.apiUrl;
  protected url: string;

  constructor(
    protected http: HttpClient
  ) {}

  get(filter: any, modifier: any, pagination?: ApiPagination) {
   return this.http.post<ApiResponse<T>>(this.apiUrl + this.url, {
     ...filter && {filter},
     ...modifier && {modifier},
     ...pagination && {pagination}
   })
  }

  update(items: Partial<T>[]) {
    return this.http.put<ApiResponse<T>>(this.apiUrl + this.url, {items})
  }

  delete(id: number[]) {
    return this.http.delete<ApiResponse<any>>(this.apiUrl + this.url, {
      body: { id }
    });
  }
}
