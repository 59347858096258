<app-page-wrapper>
  <control-panel>
    <p>Control panel UIKit</p>
  </control-panel>

  <main-content>
    <div class="wrapper-block">
      <h2>Progress:</h2>
      @for (data of progressData; track $index) {
        <div [style.width.px]="250" class="my-2">
          <app-calculation-progress
              [calculation]="data"
              [isSelf]="[1, 2].includes($index)"
              [billingModel]="$index === 2 ? 'time_and_material' : 'fix'" />
        </div>
      }
    </div>
    <div class="wrapper-block">
      <h2>Table:</h2>
      <app-table-controls (onRefresh)="setBlur()" />
      <app-table [isBlur]="isBlur" [tableConfig]="table" [filterOptions]="filterOptions"></app-table>
    </div>
    <div class="wrapper-block">
      <h2>Currency:</h2>
      <p><app-currency [value]="123423.323" currency="USD"></app-currency></p>
      <p><app-currency [value]="123423.323" currency="RUB"></app-currency></p>
    </div>
    <div class="wrapper-block">
      <h2>Duration:</h2>
      <p> <app-hour-duration [hour]="20.43"></app-hour-duration></p>
      <p> <app-hour-duration [hour]="20.43" [format]="['h']"></app-hour-duration></p>
      <p> <app-hour-duration [hour]="20.43" [format]="['m']"></app-hour-duration></p>
    </div>
    <div class="wrapper-block">
      <h2>Modal</h2>
      <div class="flex gap-2">
        <button tuiButton (click)="openModal('medium')">Open medium</button>
        <button tuiButton (click)="openModal('large')">Open large</button>
      </div>
    </div>
    <div class="wrapper-block">
      <h2>Api</h2>
      <div class="flex gap-2">
        <button tuiButton (click)="api()">Api test</button>
      </div>
    </div>
    <div class="wrapper-block">
      <h2>Alert:</h2>
      <div class="flex gap-2">
        <button tuiButton appearance="warning" (click)="openAlert()">Alert warning</button>
        <button tuiButton appearance="primary" (click)="openAlert('primary')">Alert primary</button>
        <button tuiButton appearance="success" (click)="openAlert('success')">Alert success</button>
        <button tuiButton appearance="error" (click)="openAlert('error')">Alert error</button>
      </div>
    </div>
    <div class="wrapper-block">
      <h2>Form:</h2>
      <app-form-element
        #formElement
        [formControl]="formEl"
      ></app-form-element>
    </div>

    <div class="wrapper-block">
      <h2>Table skeleton:</h2>
      <app-table-skeleton/>
    </div>
  </main-content>
</app-page-wrapper>
