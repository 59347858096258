<div [formGroup]="formGroup" class="mb-2">
  @for (controlSlug of objectKeys(formGroup.controls); track $index) {
    @if (formGroup.get(controlSlug)['field']()?.isShow) {
      @if (isFormControl(formGroup.get(controlSlug))) {
        <app-form-control
            [makeAllFormTouched]="makeAllFormTouched"
            [control]='formGroup.get(controlSlug)'
            [controlSlug]='controlSlug'
            [loader]="loader"
        ></app-form-control>
      }

      @if (isFormGroup(formGroup.get(controlSlug))) {
        <app-form-group
            [makeAllFormTouched]="makeAllFormTouched"
            [formGroup]='formGroup.get(controlSlug)'
            [loader]="loader"
        ></app-form-group>
      }

      @if (isFormArray(formGroup.get(controlSlug))) {
        <app-form-array
          [makeAllFormTouched]="makeAllFormTouched"
          [formArray]='formGroup.get(controlSlug)'
          [loader]="loader"
        ></app-form-array>
      }
    }
  }
</div>
