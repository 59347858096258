import { Injectable } from '@angular/core';
import {ApiCoreService} from '@/app/services/core/api-core.service';
import {HttpClient} from '@angular/common/http';
import {ApiEmployeeFilter, ApiEmployeeModifier} from '@/app/entities/api/types/api-employee.type';
import {ApiPagination} from '@/app/entities/api/api-pagination.entity';
import {Employee} from '@/app/entities/employee/employee.entity';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends ApiCoreService<Employee> {
  constructor(
    http: HttpClient,
  ) {
    super(http);
    this.url = 'employee';
  }

  override get(
    filter?: Partial<ApiEmployeeFilter>,
    modifier?: Partial<ApiEmployeeModifier>,
    pagination?: ApiPagination
  ) {
    return super.get(filter, modifier, pagination);
  }
}
