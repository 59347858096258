import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TuiButton, TuiCalendar, TuiDropdown} from '@taiga-ui/core';
import {CommonModule} from '@angular/common';
import {TuiDay, TuiDayRange} from '@taiga-ui/cdk';
import {DateRange} from '@/app/entities/common/date-range.entity';
import {CustomDate} from '@/app/core/classes/custom-date';


@Component({
  selector: 'app-table-date-filter',
  standalone: true,
  imports: [
    CommonModule,
    TuiButton,
    TuiDropdown,
    TuiCalendar
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableDateFilterComponent  {
  @Input() disabled: boolean = false;
  @Input() isRange: boolean = true;

  @Output() onDateChange = new EventEmitter<DateRange | null>();

  open = false;
  value: TuiDayRange | null = null;

  onDayClick(day: TuiDay): void {
    if (!this.value?.isSingleDay) {
      this.value = new TuiDayRange(day, day);
    }

    this.value = TuiDayRange.sort(this.value.from, day);

    this.onDateChange.emit({
      from: (new CustomDate(this.value.from.year, this.value.from.month, this.value.from.day)).format('yyyy-MM-dd'),
      to: (new CustomDate(this.value.to.year, this.value.to.month, this.value.to.day)).format('yyyy-MM-dd')
    })
  }

  onClear() {
    this.value = null;
    this.onDateChange.emit(null);
  }
}
