@if (calculation) {
  <div
      class="calc"
      [tuiDropdown]="dropdown"
      tuiDropdownHover
  >
    <div class="calc-block">
      @if (isTime) {
        <div class="calc-block-cost">
         {{calculation.timeSpent.value}}h
        </div>
        <div class="calc-block-percent">
          {{calculation.timeSpent.percent}}%
        </div>
      } @else {
        <div class="calc-block-cost">
          <app-currency currency="USD" [value]="calculation.profit.value" />
        </div>
        <div class="calc-block-percent">
          {{calculation.profit.percent}}%
        </div>
      }
    </div>
    <div class="calc-progress">
      <div
          class="progress"
          [ngClass]="{
          'progress-success': totalValue <= 40,
          'progress-warning': totalValue > 40 && totalValue <= 80,
          'progress-error': totalValue > 80,
        }"
      >
        <div
            class="progress-expenses progress-timeline"
            [style.width.%]="sizeData.expenses.size"
        ></div>
        <div
            class="progress-cost progress-timeline"
            [style.width.%]="sizeData.cost.size"
        ></div>
        <div
            class="progress-over progress-timeline"
            [style.width.%]="sizeData.overRevenue.size"
        >
          <div
              class="progress-over-current"
              [style.width.%]="sizeData.overRevenue.currentSize"
          ></div>
        </div>
      </div>
    </div>
  </div>


  <ng-template #dropdown>
    <div class="calc-dropdown">
      <div class="calc-dropdown-row">
        <div class="calc-dropdown-title">Revenue</div>
        <div class="calc-dropdown-cost">
          @if (!isSelf) {
            Σ
          }
          <app-currency currency="USD" [value]="calculation.revenue.value" />
          @if (isSelf) {
            @if (billingModel === 'time_and_material') {
              t&m
            } @else {
              Fix
            }
          }
        </div>
        <div class="calc-dropdown-percent">
          {{calculation.revenue.percent}}%
        </div>
      </div>
      <div class="calc-dropdown-row">
        <div class="calc-dropdown-title">Cost</div>
        <div class="calc-dropdown-cost">
          <app-currency currency="USD" [value]="calculation.cost.value" />
        </div>
        <div class="calc-dropdown-percent">
          {{calculation.cost.percent}}%
        </div>
      </div>
      <div class="calc-dropdown-row">
        <div class="calc-dropdown-title">Expenses</div>
        <div class="calc-dropdown-cost">
          <app-currency currency="USD" [value]="calculation.expenses.value + calculation.organizationExpenses.value" />
        </div>
        <div class="calc-dropdown-percent"></div>
      </div>
      <div class="calc-dropdown-row calc-dropdown-row-side">
        <div class="calc-dropdown-title">Project</div>
        <div class="calc-dropdown-cost">
          <app-currency currency="USD" [value]="calculation.expenses.value" />
        </div>
        <div class="calc-dropdown-percent">
          {{calculation.expenses.percent}}%
        </div>
      </div>
      <div class="calc-dropdown-row calc-dropdown-row-side">
        <div class="calc-dropdown-title">Company overheads</div>
        <div class="calc-dropdown-cost">
          <app-currency currency="USD" [value]="calculation.organizationExpenses.value" />
        </div>
        <div class="calc-dropdown-percent">
          {{calculation.organizationExpenses.percent}}%
        </div>
      </div>
    </div>
  </ng-template>
}
