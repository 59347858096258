import {Component, Input} from '@angular/core';
import {TreeModule} from 'primeng/tree';
import {TuiButton, TuiDropdown, TuiIcon} from '@taiga-ui/core';
import {TableFilter} from '@/app/entities/table/table-filter.entity';
import {TreeNode} from 'primeng/api';

@Component({
  selector: 'app-table-filter',
  standalone: true,
  imports: [
    TreeModule,
    TuiButton,
    TuiDropdown,
    TuiIcon
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss'
})
export class TableFilterComponent {
  @Input() filter: TableFilter;

  open = false;
  selection: any = [];
  prevSelection: TreeNode[] = [];
  isApprove = false;

  toggleOpen(open: boolean) {
    if (this.open && !open) {
      this.onClose()
    }

    if (!this.open && open) {
      this.onOpen()

    }
  }

  onClose() {
    if (!this.isApprove) {
      this.selection = [];
      this.prevSelection = [];
    }

    this.open = false;

  }

  onOpen() {
    this.selection =  this.filter.isSingle ? {...this.prevSelection[0]} : [...this.prevSelection];

    this.open = true;
  }

  onApprove() {
    this.isApprove = true;

    this.prevSelection = this.filter.isSingle ? [this.selection] : [...this.selection];
    this.onClose();

    if (this.filter.callback) {
      const keys = this.filter.isSingle
        ? this.selection ? [this.selection.key] : []
        : this.selection.map(item => item.key!);
      this.filter.callback(keys)
    }

  }

  onCancel() {
    this.onClose();
  }

  onReset() {
    this.isApprove = false;
    this.onClose();
    if (this.filter.callback) {
      this.filter.callback(null)
    }
  }

  get hasData(): boolean {
    return Array.isArray(this.selection)
      ? !!this.selection.length
      : !!this.selection;
  }
}
