import {Component, Input} from '@angular/core';
import {CurrencyFormatPipe} from '@/app/pipes/currency-format.pipe';

@Component({
  selector: 'app-currency',
  standalone: true,
  imports: [
    CurrencyFormatPipe
  ],
  templateUrl: './currency.component.html',
  styleUrl: './currency.component.scss'
})
export class CurrencyComponent {
  @Input() value: number;
  @Input() currency: string;
  @Input() applyColor: string;
}
