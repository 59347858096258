import {Component, CUSTOM_ELEMENTS_SCHEMA, inject} from '@angular/core';
import {PageWrapperComponent} from '@/app/components/page/page-wrapper/item.component';
import {ActivatedRoute, RouterLink, RouterLinkActive, RouterOutlet} from '@angular/router';
import {TuiTabs} from '@taiga-ui/kit';
import {EmployeeService} from '@/app/services/api/employee.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {of, switchMap} from 'rxjs';
import {DataList} from '@/app/entities/common/data-list.entity';

@Component({
  selector: 'app-employee-wrap-page',
  standalone: true,
  imports: [
    PageWrapperComponent,
    RouterOutlet,
    TuiTabs,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './item.component.html',
  styleUrl: './item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmployeeWrapPageComponent {
  aRoute = inject(ActivatedRoute);
  employeeService = inject(EmployeeService);

  employeeData: DataList;
  isLoading = true;

  constructor() {
    this.aRoute.params.pipe(
      switchMap(params => {
        if (!params['employeeId']) {
          return of(null);
        }
        return this.employeeService.get({
          id: [params['employeeId']],
        }, {
          isDataList: true
        });
      }),
      takeUntilDestroyed(),
    ).subscribe({
      next: res => {
        if (res?.isSuccess) {
          this.employeeData = res.result.items[0] as unknown as DataList;
        }
        this.isLoading = false;
      },
      error: err => {
        this.isLoading = false;
      }
    })
  }

  routes = [
    {
      label: 'Issues',
      url: 'issues'
    },
    {
      label: 'Contracts',
      url: 'contracts'
    },
  ]
}
