
import {TuiRoot, TuiAlert, TuiDialog, TuiButton, TUI_DARK_MODE_KEY, TUI_DARK_MODE} from "@taiga-ui/core";
import {Component, effect, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {ButtonDirective} from 'primeng/button';
import {WA_LOCAL_STORAGE, WA_WINDOW} from '@ng-web-apis/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    TuiRoot,
    TuiDialog,
    TuiAlert,
    ButtonDirective,
    TuiButton
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  private readonly key = inject(TUI_DARK_MODE_KEY);
  private readonly storage = inject(WA_LOCAL_STORAGE);
  private readonly media = inject(WA_WINDOW).matchMedia('(prefers-color-scheme: dark)');

  protected readonly darkMode = inject(TUI_DARK_MODE);

  title = 'budget-budger';

  constructor() {
    effect(() => {
      const body = document.body;
      body.setAttribute('tuiTheme', this.darkMode() ? 'dark' : 'light');
    });
  }
}
