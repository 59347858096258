import {Directive, ElementRef, HostListener, Input, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
  selector: 'input[regexInput]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputPatternDirective,
      multi: true
    }
  ]
})
export class InputPatternDirective implements ControlValueAccessor {

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
  ) { }

  @Input()
  set regexInput(regex: 'integer' | string | RegExp) {
    if (regex === 'integer') {
      this._regex = new RegExp('^[0-9]*$', "g");
      return
    }
    this._regex = (typeof regex === 'string') ? new RegExp('^' + regex + '$', "g") : regex;
  }

  private prevValue?: string;
  private _regex!: RegExp;

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    if (!this._regex) {
      throw new Error('RegExp required for regexInput');
    }
    let val = (<HTMLInputElement>event.target).value;
    if (val.search(this._regex) == -1) {
      this.elementRef.nativeElement.value = this.prevValue ?? "";
    } else {
      this.prevValue = val;
      this.onChange(val);
    }
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(val: any): void {
    const _value = `${val ?? ''}`;
    this.elementRef.nativeElement.value = _value;
    this.prevValue = _value;

  }

  onChange = (value: any) => { };

  onTouched = () => { };

  registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
